import React from 'react';
import { useApi } from 'services/axios';
import { RadioGroup } from 'components/RadioGroup';
import { SelectDropdown } from 'components/SelectDropdown';
import { useFormContext, Controller } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { Loader } from 'components/Loader';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const Verzeichnis_Step6 = () => {
  const [countries, setCountries] = React.useState(null);
  const [guarantees, setGuarantees] = React.useState(null);
  const [hasFetched, setHasFetched] = React.useState(false);
  const { control, watch, unregister, formState } = useFormContext();
  const { api } = useApi();
  const { t } = useTranslation('common');
  const validation = useValidation();
  const bekanntgabeAnswer = watch('step6.bekanntgabe');

  const options = t('radioOptions', { returnObjects: true });

  const fetchData = async () => {
    const countriesResponse = await api.get('/verzeichnis_countries');
    const guaranteesResponse = await api.get('/verzeichnis_guarantees');

    setCountries(countriesResponse.data);
    setGuarantees(guaranteesResponse.data);
  };

  React.useEffect(() => {
    if (api && !hasFetched) {
      fetchData();
      setHasFetched(true);
    }
  }, [api]);

  React.useEffect(() => {
    if (bekanntgabeAnswer === 'no') {
      unregister('step6.land');
      unregister('step6.angemessener');
      unregister('step6.garantien');
    }
  }, [bekanntgabeAnswer]);

  return (
    <div className="card-body">
      <h4>Ausland</h4>
      <div className={styles.steptext}>
        {t('verzeichnis.steps.6.description')}
      </div>
      <div className={styles.verzeichnisInputsWrapper}>
        <Controller
          name="step6.bekanntgabe"
          control={control}
          rules={{ ...validation.required }}
          render={({ field }) => (
            <RadioGroup
              label={t('verzeichnis.steps.6.inputs.disclosureAbroad')}
              name="bekanntgabe"
              options={options}
              onChange={field.onChange}
              value={field.value}
              error={formState.errors?.step6?.bekanntgabe}
            />
          )}
        />

        {(!countries || !guarantees) && <Loader />}

        {countries && bekanntgabeAnswer === 'yes' && (
          <Controller
            name="step6.land"
            control={control}
            rules={{ ...validation.required }}
            render={({ field }) => (
              <SelectDropdown
                label={t('verzeichnis.steps.6.inputs.country')}
                 /*       KEVIN B könnte man eine neue spalte bei den Ländern einfügen, mit 1 oder 0, 
                 1= angemessener Schutz gemäss EDÖB, 0 = Kein angemessener Schutz,
                 damit könnte dann die folgefrage"angemessener Schutz gemäss EDÖB weggelassen werden, wenn nur Länder mit 1 gewählt werden  */
                options={countries.map((country) => {
                  return {
                    label: country.CountryName,
                    value: country.CountryId,
                  };
                })}
                value={field.value}
                onChange={field.onChange}
                error={formState.errors?.step6?.land}
                name={field.name}
                isMulti
              />
            )}
          />
        )}
        {bekanntgabeAnswer === 'yes' && (
           <Controller
           name="step6.angemessener"
           control={control}
           rules={{ ...validation.required }}
           render={({ field }) => (
             <>
               <label htmlFor="angemessener"
                style={{ marginBottom: '-10px', marginTop: '10px' }}  
               >
                 Angemessener Schutz gemäss{" "}
                 <a href="https://www.fedlex.admin.ch/eli/cc/2022/568/de#annex_1" target="_blank" rel="noopener noreferrer">
                   EDÖB
                 </a>{" "}
                 gewährleistet
               </label>
               <RadioGroup
                 label="" 
                 name="angemessener"
                 options={options}
                 onChange={field.onChange}
                 value={field.value}
                 error={formState.errors?.step6?.angemessener}
               />
             </>
           )}
         />
        )}
        {guarantees && bekanntgabeAnswer === 'yes' && (
          <Controller
            control={control}
            name="step6.garantien"
            rules={{ ...validation.required }}
            render={({ field }) => (
              <SelectDropdown
                label={t('verzeichnis.steps.6.inputs.guarantees')}
                options={guarantees.map((guarantee) => {
                  return {
                    label: guarantee.GuaranteeName,
                    value: guarantee.GuaranteeId,
                  };
                })}
                isMulti
                isCreatable
                value={field.value}
                onChange={field.onChange}
                error={formState.errors?.step6?.garantien}
                name={field.name}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export { Verzeichnis_Step6 };
