import React, { useEffect, useState } from 'react';

import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import {
  Home,
  Profil,
  Programme,
  Dokumente,
  Support,
  Einstellungen,
  LoginPage,
} from 'pages';
import {
  Verzeichnis,
  VerzeichnisOverview,
  VerzeichnisDetailedView,
} from 'Programme/Verzeichnis';
import { ProtectedRoute } from './components/protectedRoute';
import { ToastContainer } from 'react-toastify';
import { useMsal } from '@azure/msal-react';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import { Datenschutzverletzung } from 'Programme/Datenschutzverletzung/Datenschutzverletzung';
import { DatenschutzverletzungOverview } from 'Programme/Datenschutzverletzung/DatenschutzverletzungOverview';
import { DatenschutzverletzungDetailedView } from 'Programme/Datenschutzverletzung/DatenschutzverletzungDetailedView';
import { DSVUpload } from 'Programme/Datenschutzverletzung/upload';

import { Dsfa } from 'Programme/Datenschutzfolgeabschaetzung/Dsfa';
import { DsfaOverview } from 'Programme/Datenschutzfolgeabschaetzung/DsfaOverview';
import { DsfaDetailedView } from 'Programme/Datenschutzfolgeabschaetzung/DsfaDetailedView';
import { DsfaUpload } from 'Programme/Datenschutzfolgeabschaetzung/upload';

import { Bearbeitungsreglement } from 'Programme/Bearbeitungsreglement';
import { BROverview } from 'Programme/Bearbeitungsreglement/BROverview';
import { BRDetailedView } from 'Programme/Bearbeitungsreglement/BRDetailedView';
import { BRUpload } from 'Programme/Bearbeitungsreglement/upload';

import { Dsa } from 'Programme/DatenschutzerklarungNew/Dsa';
import { DsaOverview } from 'Programme/DatenschutzerklarungNew/DsaOverview';
import { DsaDetailedView } from 'Programme/DatenschutzerklarungNew/DsaDetailedView';
import { DsaUpload } from 'Programme/DatenschutzerklarungNew/upload';

import { Advv } from 'Programme/Auftragsdatenverarbeitungsvertrag/advv';
import { AdvvUpload } from 'Programme/Auftragsdatenverarbeitungsvertrag/upload';

import { AA } from 'Programme/Auskunftsanfrage/aa';
import { AAUpload } from 'Programme/Auskunftsanfrage/upload';

import { Ghv } from 'Programme/Geheimhaltungsvereinbarung/ghv';
import { GhvUpload } from 'Programme/Geheimhaltungsvereinbarung/upload';

import { VerzeichnisUpload } from 'Programme/Verzeichnis/upload';

import { Cyberver } from 'Programme/Cyberversicherung/cyberver';
import { CyberverUpload } from 'Programme/Cyberversicherung/upload';

import { Toms } from 'Programme/Toms/Toms';
import { TomsOverview } from 'Programme/Toms/TomsOverview';
import { TomsDetailedView } from 'Programme/Toms/TomsDetailedView';
import { TomsUpload } from 'Programme/Toms/upload';

import { Standortbestimmung } from 'Programme/Standortbestimmung/Standortbestimmung';
import { StandortbestimmungOverview } from 'Programme/Standortbestimmung/StandortbestimmungOverview';
import { StandortbestimmungDetailedView } from 'Programme/Standortbestimmung/StandortbestimmungDetailedView';
import { StandortbestimmungUpload } from 'Programme/Standortbestimmung/upload';

import { AdminPanel } from 'pages/AdminPanel';


//Vertragsmanagement
import { ContractOverview } from 'Vertragsmanagement/Contract/';
import { VMOverview } from 'Vertragsmanagement/General/';
//import { ContractFormular } from 'Vertragsmanagement/Contract/Formular';

// import { Dse } from 'Programme/Datenschutzerklarung/dse';





function App() {
  const [token, setToken] = useState(() => {
    const storedToken = localStorage.getItem('authToken');
    return storedToken ? storedToken : null;
  });
  const { accounts } = useMsal();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (accounts[0] && location.pathname === '/') {
      navigate('/Programme');
    }
  }, [accounts, location]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route
          path="/*"
          element={<ProtectedRoute isAuthenticated={!!accounts[0]} />}
        >
          <Route path="Home" element={<Home />} />
          <Route path="Profil" element={<Profil />} />
          <Route path="Programme" element={<Programme />} />
          <Route path="Dokumente" element={<Dokumente />} />
          <Route path="Einstellungen" element={<Einstellungen />} />
          <Route path="Support" element={<Support />} />
          <Route path="Verzeichnis" element={<Verzeichnis />}>
            <Route path=":id" element={<Verzeichnis />} />
          </Route>
          <Route
            path="VerzeichnisOverview/:id"
            element={<VerzeichnisDetailedView />}
          />
          <Route path="VerzeichnisOverview" element={<VerzeichnisOverview />} />
          <Route path="VerzeichnisUpload" element={<VerzeichnisUpload />} />
          <Route
            path="Datenschutzverletzung"
            element={<Datenschutzverletzung />}
          >
            <Route path=":id" element={<Datenschutzverletzung />} />
          </Route>
          <Route
            path="DatenschutzverletzungOverview"
            element={<DatenschutzverletzungOverview />}
          />
          <Route path="DatenschutzverletzungUpload" element={<DSVUpload />} />
          <Route
            path="DatenschutzverletzungOverview/:id"
            element={<DatenschutzverletzungDetailedView />}
          />
          <Route path="BR" element={<Bearbeitungsreglement />}>
            <Route path=":id" element={<Bearbeitungsreglement />} />
          </Route>
          <Route path="BROverview" element={<BROverview />} />
          <Route path="BROverview/:id" element={<BRDetailedView />} />
          <Route path="BRUpload" element={<BRUpload />} />
          <Route path="Datenschutzfolgeabschaetzung" element={<Dsfa />}>
            <Route path=":id" element={<Dsfa />} />
          </Route>
          <Route
            path="DatenschutzfolgeabschaetzungOverview"
            element={<DsfaOverview />}
          />
          <Route
            path="DatenschutzfolgeabschaetzungOverview/:id"
            element={<DsfaDetailedView />}
          />
          <Route path="DsfaUpload" element={<DsfaUpload />} />
        </Route>
        <Route path="Toms" element={<Toms />}>
          <Route path=":id" element={<Toms />} />
        </Route>
        <Route path="TomsOverview/:id" element={<TomsDetailedView />} />
        <Route path="TomsOverview" element={<TomsOverview />} />
        <Route path="TomsUpload" element={<TomsUpload />} />
        <Route path="Standortbestimmung" element={<Standortbestimmung />}>
          <Route path=":id" element={<Standortbestimmung />} />
        </Route>
        <Route path="StandortbestimmungOverview/:id" element={<StandortbestimmungDetailedView />} />
        <Route path="StandortbestimmungOverview" element={<StandortbestimmungOverview />} />
        <Route path="StandortbestimmungUpload" element={<StandortbestimmungUpload />} />
        <Route path="Dsa" element={<Dsa />}>
          <Route path=":id" element={<Dsa />} />
        </Route>
        <Route path="DsaOverview" element={<DsaOverview />} />
        {/* <Route path="DsaUpload" element={<DSVUpload />} /> */}
        <Route path="DsaOverview/:id" element={<DsaDetailedView />} />
        <Route path="DsaUpload" element={<DsaUpload />} />
        {/* <Route path="Dse" element={<Dse />} />
        <Route path="DseUpload" element={<DseUpload />} /> */}
        <Route path="Advv" element={<Advv />} />
        <Route path="AdvvUpload" element={<AdvvUpload />} />
        <Route path="Aa" element={<AA />} />
        <Route path="AaUpload" element={<AAUpload />} />
        <Route path="Ghv" element={<Ghv />} />
        <Route path="GhvUpload" element={<GhvUpload />} />
        <Route path="Cyberver" element={<Cyberver />} />
        <Route path="CyberverUpload" element={<CyberverUpload />} />
        <Route path="AdminPanel" element={<AdminPanel />} />
        <Route path="ContractOverview" element={<ContractOverview />} />
        <Route path="VMOverview" element={<VMOverview />} />

      </Routes>
      <ToastContainer position="bottom-right" />
    </div>
  );
}

export default App;
