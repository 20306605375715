import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Textarea } from 'components/Textarea';
import { useValidation } from 'helpers/validation';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const Verzeichnis_Step4 = () => {
  const { control, formState } = useFormContext();
  const { t } = useTranslation('common');
  const validation = useValidation();

  return (
    <div className="card-body">
      <h4>Aufbewahrung und Sicherheit</h4>
      <div className={styles.steptext}>
        {t('verzeichnis.steps.4.description')}
      </div>
      <div className={styles.verzeichnisInputsWrapper}>
        <Controller
          control={control}
          name="step4.aufbewahrung"
 /*       KEVIN kannst du hier Auswahlfelder machen? 
          'Gesetzliche Aufbewahrungsfrist von 10 Jahren',
          'Gesetzliche Aufbewahrungsfrist von 20 Jahren',
          'Bis zum Ende des Geschäftsverhältnisses',
          'Bis zum Ende der Zusammenarbeit',
          'Löschung nach 1 Jahr',
          'Löschung nach 2 Jahren',
          'Löschung nach 5 Jahren',
          'Dauerhafte Aufbewahrung',
          Individueller Text muss auch möglich sein */
          rules={{ ...validation.required }}
          render={({ field }) => {
            return (
              <Textarea
                label={t('verzeichnis.steps.4.inputs.storage')}
                id="verzeichnis-aufbewahrung"
                error={formState.errors?.step4?.aufbewahrung}
                tooltipText="Beschreiben Sie hier, wie lange die Daten aufbewahrt werden, zusätzlich oder anstelle davon können Sie auch Kriterien zur Aufbewahrungsdauer beschreiben, bspw. «Gesetzliche Aufbewahrungsfrist von 10 Jahren»."
                {...field}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="step4.massnahmenDatensicherheit"
 /*       KEVIN kannst du hier Auswahlfelder machen? --> Entscheid: als Vorlagetext
          'gemäss Liste der technischen und organisatorischen Massnahmen',
          Individueller Text muss auch möglich sein */
          rules={{ ...validation.required }}
          render={({ field }) => (
            <Textarea
              label={t('verzeichnis.steps.4.inputs.securityMeasures')}
              id="verzeichnis-massnahmen"
              error={formState.errors?.step4?.massnahmenDatensicherheit}
              tooltipText="Bitte beschreiben Sie hier, was unternommen wird, um den Schutz der Daten sicherzustellen."
              {...field}
            />
          )}
        />
      </div>
    </div>
  );
};

export { Verzeichnis_Step4 };
