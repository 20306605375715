import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useApi } from 'services/axios';
import { Navbar, Loader, MobileNavbar } from 'components';
import { useTranslation } from 'react-i18next';
import { DetailedViewPdf } from './detailedViewPdf';
import TipBar from 'components/TipBar';
import styles from './styles.module.css';

const VerzeichnisDetailedView = () => {
  const { id } = useParams();
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [companyData, setCompanyData] = React.useState(null);
  const { api } = useApi();
  const { t } = useTranslation('common');

  const LABELS = t('detailedView.verzeichnis.labels', { returnObjects: true });

  const fetchEntry = async () => {
    if (!api) {
      console.error('API is not initialized');
      setError(new Error('API is not initialized'));
      return;
    }
    try {
      console.log('Fetching data for entry with id:', id); // Hinzugefügtes Log
      const entryResponse = await api.get(`/verzeichnis_alldata/${id}`);
      const companyResponse = await api.get(`/companydata`);
      setData(entryResponse.data);
      setCompanyData(companyResponse.data[0]);
      setError(null);
    } catch (err) {
      console.error('Error fetching entry: ', err);
      setError(err);
    }
  };

  React.useEffect(() => {
    if (api) {
      console.log('API is initialized, fetching data...'); // Hinzugefügtes Log
      fetchEntry();
    } else {
      console.log('API is not yet initialized'); // Hinzugefügtes Log
    }
  }, [api]);

  if (error)
    return (
      <div className="wrapper">
        <div id="content">
          <Link to="/VerzeichnisOverview" className={styles.detailedViewLink}>
            <button className="btn btn-default">
              {t('detailedView.backToOverview')}
            </button>
          </Link>
          <h2>{t('detailedView.notFound')}</h2>
        </div>
      </div>
    );

  if (!data)
    return (
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    );

  const renderStep = (stepData, stepLabels, isStep6 = false) => {
    if (!stepData) return null;

    if (isStep6 && !Object.values(stepData).includes('yes')) {
      return Object.keys(stepData).map((point, index) => {
        if (index === 0) {
          return renderPoint(stepData[point], stepLabels[point], point);
        }
        return null;
      });
    }

    return Object.keys(stepData).map((point) =>
      renderPoint(stepData[point], stepLabels[point], point)
    );
  };

  const renderPoint = (dataPoint, label, key) => {
    if (typeof dataPoint === 'string') {
      return (
        <div className={styles.categoryPoints} key={key}>
          <div className={styles.categoryPoint}>
            <span className={styles.categoryPointTitle}>
              {label}
            </span>
            <span className={styles.categoryPointText}>
              {dataPoint === 'yes' ? 'Ja' : dataPoint === 'no' ? 'Nein' : dataPoint}
            </span>
          </div>
        </div>
      );
    } else if (Array.isArray(dataPoint?.preset)) {
      return (
        <div className={styles.categoryPoints} key={key}>
          <div className={styles.categoryPoint}>
            <h5 className={styles.categoryPointTitle}>{label}</h5>
            <ul className={styles.categoryPointList}>
              {dataPoint.preset.map((obj, i) => (
                <li className={styles.categoryPointListItem} key={i}>
                  {obj.label}
                </li>
              ))}
              {dataPoint.custom.map((obj, i) => (
                <li className={styles.categoryPointListItem} key={i}>
                  {obj.label}
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.mainInfoPoints} key={key}>
          <span className={styles.categoryPointTitle}>{label}</span>
          <ul className={styles.categoryPointList}>
            {dataPoint?.map((country, i) => (
              <li className={styles.categoryPointListItem} key={i}>
                {country.label}
              </li>
            ))}
          </ul>
        </div>
      );
    }
  };

  return (
    <div className="wrapper" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
      <TipBar />
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
        <Navbar />
        <MobileNavbar />

        <div
          className={`${styles.detailedViewWrapper} morph_variation1`}
          id="content"
        >
          <div className={styles.detailedViewHeadingWrapper}>
            <div className={styles.detailedViewTitleWrapper}>
              <Link to="/VerzeichnisOverview" className={styles.detailedViewLink}>
                <button className="btn btn-default">
                  {t('detailedView.backToOverview')}
                </button>
              </Link>
            </div>

            <div className={styles.detailedViewLinks}>
              <Link to={`/Verzeichnis/${id}`} className={styles.detailedViewLink}>
                <button className="btn btn-primary">
                  {t('detailedView.edit')}
                </button>
              </Link>
              <PDFDownloadLink
                document={
                  <DetailedViewPdf
                    data={{ ...data, id, companyInfo: companyData }}
                  />
                }
                fileName={`Bearbeitungsverzeichnis_Eintrag_-${id}`}
              >
                {({ loading }) => (
                  <button className="btn btn-default">
                    {loading
                      ? 'Loading document...'
                      : t('detailedView.downloadPDF')}
                  </button>
                )}
              </PDFDownloadLink>
            </div>
          </div>

          <hr />

          {/* // Abschnitt Step1 */}
          {Object.keys(data.step1).map((point) => (
            <div className={styles.mainInfoPoints} key={point}>
              <div className={styles.mainInfoPoint}>
                <span className={styles.mainInfoPointTitle} style={{ textTransform: 'capitalize' }}>
                  {point}
                </span>
                <span className={styles.mainInfoPointValue}>
                  {data.step1[point]}
                </span>
              </div>
            </div>
          ))}

          <hr />

          {/* // Abschnitt Step2 */}
          <div className={styles.gapWrapper3}>
            {renderStep(data.step2, LABELS.step2)}
          </div>

          <hr />

          {/* // Abschnitt Step3 */}
          <div className={styles.gapWrapper2}>
            {renderStep(data.step3, LABELS.step3)}
          </div>

          <hr />

          {/* // Abschnitt Step4 */}
          <div className={styles.gapWrapper2}>
            {renderStep(data.step4, LABELS.step4)}
          </div>

          <hr />

          {/* // Abschnitt Step5 */}
          <div className={styles.gapWrapper2}>
            {renderStep(data.step5, LABELS.step5)}
          </div>

          <hr />

          {/* // Abschnitt Step6 */}
          <div className={styles.gapWrapper2}>
            {renderStep(data.step6, LABELS.step6, true)}
          </div>

          <hr />

          {/* // Abschnitt Step7 */}
          <div className={styles.gapWrapper2}>
            {renderStep(data.step7, LABELS.step7)}
          </div>

          <hr />

          {/* // Abschnitt Step8 */}
          <div className={styles.gapWrapper2}>
            {renderStep(data.step8, LABELS.step8)}
          </div>

          <hr />

          <div className={styles.gapdates}>
            <span>{t('detailedView.dateCreated')}</span>
            <span>{data.dateCreated.replace(/-/g, '.')}</span>
            <br />
            <span>{t('detailedView.lastEdited')}</span>
            <span>{data.lastEdited.replace(/-/g, '.')}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { VerzeichnisDetailedView };
