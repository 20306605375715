import React, { useState } from 'react';
import { Textarea } from 'components/Textarea';
import { RadioGroup } from 'components/RadioGroup';
import { useFormContext, Controller } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const Verzeichnis_Step8 = () => {
  const { control, formState, watch } = useFormContext();
  const { t } = useTranslation('common');
  const validation = useValidation();

  const options = t('radioOptions', { returnObjects: 'true' });
  
  // Watch the value of the radio group
  const einzelentscheideValue = watch('step8.einzelentscheide');

  return (
    <div className="card-body">
      <h4>Automatisierungen</h4>
      <div className={styles.steptext}>
        {' '}
        Der Begriff "automatisierte Einzelentscheidungen" bezieht sich auf
        Entscheidungen, die ausschliesslich auf automatischer Datenverarbeitung
        basieren und keine manuelle Überprüfung oder Eingriff durch Menschen
        erfordern. Geben Sie an, ob bei dieser Beabeitung automatisierte
        Einzelentscheide gefällt werden und erfassen Sie allfällige Bemerkungen.{' '}
      </div>

      <Controller
        control={control}
        name="step8.einzelentscheide"
        rules={{ ...validation.required }}
        render={({ field }) => (
          <RadioGroup
            label={t('verzeichnis.steps.8.inputs.automatedDecisions')}
            options={options}
            error={formState.errors?.step8?.einzelentscheide}
            {...field}
          />
        )}
      />

      {einzelentscheideValue === 'yes' && (
        <Controller
          control={control}
          name="step8.bemerkungen"
          render={({ field }) => (
            <Textarea
              label={t('verzeichnis.steps.8.inputs.remarks')}
              id="verzeichnis-bemerkungen"
              error={formState.errors?.step8?.bemerkungen}
              {...field}
            />
          )}
        />
      )}
    </div>
 /*   KEVIN kann ich hier noch Folgefragen haben, wenn Automatisierung = ja gewählt wird
 
1. Wird ein Profiling mit hohem Risiko durchgeführt? x ja, x nein: 
tooltip(Profiling, das ein hohes Risiko für die Persönlichkeit oder die Grundrechte der 
betroffenen Person mit sich bringt, indem es zu einer Verknüpfung von Daten führt, die eine 
Beurteilung wesentlicher Aspekte der Persönlichkeit einer natürlichen Person erlaubt;)
*/
  );
};

export { Verzeichnis_Step8 };