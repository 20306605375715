import React from 'react';
import { useApi } from 'services/axios';
import styles from './styles.module.css';
import detailedStyles from '../../Toms/TomsDetailedView/styles.module.css';
import { TITLES } from 'Programme/Toms/TomsDetailedView';
import { Loader } from 'components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const Dsa_Step6 = () => {
  const [tomsEntries, setTomsEntries] = React.useState(null);
  const [tomsEntriesDetails, setTomsEntriesDetails] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const { t } = useTranslation('common');
  const { register, formState, watch } = useFormContext();
  const [error, setError] = React.useState(null);
  const { api } = useApi();

  const showTomsValue = watch('step6.showToms');

  const fetchData = React.useCallback(async () => {
    if (!api) return;
    setIsLoading(true);
    try {
      const tomsResponse = await api.get(`/toms_alldata`);
      setTomsEntries(tomsResponse.data.entries);
    } catch (err) {
      console.error('Error fetching entry: ', err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  }, [api]);

  const fetchDetails = React.useCallback(async (id) => {
    if (!api) return;
    if (id) {
      try {
        const detailsResponse = await api.get(`/toms_alldata/${id}`);
        if (
          tomsEntriesDetails &&
          tomsEntriesDetails.find((entry) => entry.id === id)
        )
          return;
        setTomsEntriesDetails((prev) => [
          ...(prev ?? []),
          { ...detailsResponse.data, id },
        ]);
      } catch (err) {
        console.error(`Error fetching details for ID ${id}: `, err);
      }
    }
  }, [api, tomsEntriesDetails]);

  React.useEffect(() => {
    if (showTomsValue === 'toms' && !tomsEntries && api) {
      fetchData();
    }
  }, [showTomsValue, tomsEntries, api, fetchData]);

  React.useEffect(() => {
    if (tomsEntries && tomsEntries.length === 0) {
      setTomsEntriesDetails([]);
      return;
    }
    if (tomsEntries) {
      tomsEntries.forEach((entry) => fetchDetails(entry.id));
    }
  }, [tomsEntries, fetchDetails]);

  return (
    <div className="card-body">
      <h4>{t('dsa.steps.6.title')}</h4>
      <div className={styles.dsaInputsWrapper} style={{ marginTop: '20px' }}>
        <div>
          <p>Möchten Sie die von Ihnen notierten TOMs (Technische und organisatorische Massnahmen) 
            in der Datenschutzerklärung auflisten einen Standardtext verwenden?</p>
          <label>
            <input
              {...register('step6.showToms', {
                required: t('validation.required'),
              })}
              type="radio"
              value="standard"
            />
            Standardtext verwenden
          </label>
          <br></br>
          <label>
            <input
              {...register('step6.showToms', {
                required: t('validation.required'),
              })}
              type="radio"
              value="toms"
            />
            TOMS verwenden
          </label>
        </div>

        {showTomsValue === 'standard' && (
          <div>
            <p>Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst und haben technische 
              und organisatorische Massnahmen getroffen, um die Sicherheit Ihrer Daten zu gewährleisten. 
              Insbesondere speichern und verarbeiten wir unsere Daten unter hohen Sicherheitsstandards.</p>
          </div>
        )}

        {showTomsValue === 'toms' && (
          <>
            {isLoading && <Loader />}
            {!isLoading && error && <p>Error: {error.message}</p>}
            {!isLoading && tomsEntriesDetails && tomsEntriesDetails.length === 0 && (
              <span>{t('dsa.steps.6.notfound')}</span>
            )}
            {!isLoading && tomsEntriesDetails && tomsEntriesDetails.length > 0 && (
              <div>
                <div className={detailedStyles.categoryPoints}>
                  {tomsEntriesDetails.map((entry) => (
                    <React.Fragment key={entry.id}>
                      <span>Entry ID: {entry.id}</span>

                      <label htmlFor={`tomsEntry-${entry.id}`}>
                        <input
                          {...register('step6.selectedTomsId', {
                            required: t('validation.required'),
                          })}
                          type="radio"
                          value={entry.id}
                          id={`toms-${entry.id}`}
                          style={{
                            accentColor: 'rgb(165, 94, 234)',
                            marginRight: '5px',
                          }}
                        />
                        Diesen Eintrag aus dem TOMS-Modul verwenden
                      </label>

                      {Object.keys(entry).map((step, idx) => {
                        return Array.isArray(
                          entry[step]?.[Object.keys(entry[step] ?? {})[0]]
                        ) ? (
                          <div style={{ width: '100%' }} key={idx}>
                            <h5 className={detailedStyles.categoryPointTitle}>
                              {TITLES[idx]}
                            </h5>

                            <ul className={detailedStyles.categoryPointList}>
                              {Object.keys(entry[step]).map((key) => {
                                return entry[step][key].map((obj, i) => (
                                  <li
                                    className={
                                      detailedStyles.categoryPointListItem
                                    }
                                    key={i}
                                  >
                                    {obj.label}
                                  </li>
                                ));
                              })}
                            </ul>
                          </div>
                        ) : null;
                      })}
                      <div
                        className={detailedStyles.mainInfoPoints}
                        style={{ width: '100%' }}
                      >
                        <div className={detailedStyles.mainInfoPoint}>
                          <span className={detailedStyles.mainInfoPointTitle}>
                            Weitere Massnahmen
                          </span>
                          <span className={detailedStyles.mainInfoPointValue}>
                            {entry.step12.furtherMeasures}
                          </span>
                        </div>
                        <div className={detailedStyles.mainInfoPoint}>
                          <span className={detailedStyles.mainInfoPointTitle}>
                            Zertifizierungen
                          </span>
                          <span className={detailedStyles.mainInfoPointValue}>
                            {entry.step12.certificates}
                          </span>
                        </div>
                      </div>
                      <hr style={{ marginBottom: '20px' }} />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <span className={styles.errorMsg}>
        {formState.errors?.step6?.showToms?.message || formState.errors?.step6?.selectedTomsId?.message}
      </span>
    </div>
  );
};

export { Dsa_Step6 };