import React from 'react';
import { useApi } from 'services/axios';
import { SelectDropdown, Loader, Input } from 'components';
import { useFormContext, Controller } from 'react-hook-form';

import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { useValidation } from 'helpers/validation';

const COMPANY_INPUTS = [
  { label: 'Firmenname', id: 'CompanyName' },
  { label: 'Stadt', id: 'CompanyCity' },
  { label: 'Strasse', id: 'CompanyStreet' },
  { label: 'Nummer', id: 'CompanyStreetNr' },
  { label: 'Kontaktperson', id: 'CompanyContactPerson' },
  { label: 'Postleitzahl', id: 'CompanyZIP' },
  { label: 'Telefonnummer', id: 'CompanyPhone' },
];

const Dsa_Step1 = () => {
  const [companyData, setCompanyData] = React.useState(null);
  const [lawOptions, setLawOptions] = React.useState(null);
  const { control, formState, watch } = useFormContext();
  const isCustomCompany = watch('step1.company')?.value === 'custom';
  const [error, setError] = React.useState(null);
  const { api } = useApi();
  const validation = useValidation();
  const { t } = useTranslation('common');


  const fetchData = async () => {
    try {
      const companyResponse = await api.get(`/companydata`);
      const lawResponse = await api.get(`/dsa_law`);

      setLawOptions(lawResponse.data ?? []);
      setCompanyData(companyResponse.data[0] ?? {});
    } catch (err) {
      console.error('Error fetching entry: ', err);
      setError(err);
    }
  };

  React.useEffect(() => {
    if (api && !companyData) {
      fetchData();
    }
  }, [api]);

  const companyInfoExists = !!companyData?.CompanyName;

  const companyOptions = companyInfoExists
    ? [
        { label: companyData.CompanyName, value: companyData.CompanyId },
        { label: 'benutzerdefiniert', value: 'custom' },
      ]
    : null;

  const renderCompanyInputs = () =>
    COMPANY_INPUTS.map((input) => (
      <Controller
        key={input.id}
        control={control}
        name={`step1.customCompany.${input.id}`}
        rules={{ ...validation.required }}
        defaultValue=""
        render={({ field }) => {
          return (
            <Input
              {...input}
              {...field}
              error={formState.errors?.step1?.customCompany?.[input.id]}
            />
          );
        }}
      />
    ));

  return (
    <div className="card-body">
      <h4>{t('dsa.steps.1.description')}</h4>

      <div className={styles.dsaInputsWrapper} style={{ marginTop: '40px' }}>
        {!companyData && !lawOptions && <Loader />}
        {companyData && companyOptions && (
          <Controller
            control={control}
            name="step1.company"
            rules={{ ...validation.required }}
            render={({ field }) => {
              return (
                <SelectDropdown
                  options={companyOptions}
                  label="Verantwortliche Firma oder Person"
                  isCreatable
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  value={
                    field.value?.custom
                      ? field.value.custom[field.value.custom.length - 1]
                      : field.value
                  }
                  error={formState.errors?.step1?.company}
                  name={field.name}
                  customColor="#fa8231"
                />
              );
            }}
          />
        )}

        {isCustomCompany && renderCompanyInputs()}

        {lawOptions && (
          <Controller
            control={control}
            name="step1.law"
            rules={{ ...validation.required }}
            render={({ field }) => (
              <SelectDropdown
                options={lawOptions.map((option) => ({
                  value: option.LawId,
                  label: option.LawName,
                }))}
                label="Anwendbares Gesetz"
                onChange={field.onChange}
                value={field.value}
                error={formState.errors?.step1?.law}
                name={field.name}
                customColor="#fa8231"
              />
            )}
          />
        )}
      </div>
      <span className={styles.errorMsg}>
        {formState.errors?.step1?.ArbeitsundBerufsdaten?.message}
      </span>
    </div>
  );
};

export { Dsa_Step1 };
