import { Tooltip } from 'react-tooltip';
import { FormField } from '../FormField';

import styles from './styles.module.css';

const Textarea = ({
  label,
  id,
  value,
  onChange,
  error,
  name,
  tooltipText,
  rows,
}) => {
  const tooltipAnchor = `tooltipAnchor${name.split('.').pop()}`;

  return (
    <FormField error={error}>
      <div className={styles.textareaWrapper}>
        <div className={styles.labelWrapper}>
          {label && (
            <label
              className={styles.textareaLabel}
              htmlFor={id}
              style={{ marginBottom: 0 }}
            >
              {label}
            </label>
          )}
          {tooltipText && (
            <>
              <span className={`${styles.tooltipWrapper} ${tooltipAnchor}`}>
                ?
              </span>
              <Tooltip anchorSelect={`.${tooltipAnchor}`} place="top">
                {tooltipText}
              </Tooltip>
            </>
          )}
        </div>
        <textarea
          value={value}
          onChange={onChange}
          className={styles.textarea}
          rows={rows}
          id={id}
        />
      </div>
    </FormField>
  );
};

export { Textarea };
