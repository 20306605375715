import React from 'react';
import { Image, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import Logo_Red_New from '../../../../src/assets/images/Logo_Red_New.png';
import { useTranslation } from 'react-i18next';

const DetailedViewPdf = ({ data }) => {
  const { companyInfo } = data;
  const { t } = useTranslation('common');

  const LABELS = t('detailedView.verzeichnis.labels', { returnObjects: true });

  const pdfStyles = StyleSheet.create({
    pageContainer: { paddingVertical: 10, paddingHorizontal: 10 },
    documenttitle: { fontSize: 11, textAlign: 'left', padding: 2, marginBottom: 10 },
    documentsubtitle: { fontSize: 9, textAlign: 'left', padding: 2, marginBottom: 4 },
    documentcompanydata: { fontSize: 10, textAlign: 'left', padding: 2, marginBottom: 30 },
    section: { display: 'flex', flexDirection: 'column', marginBottom: 5 },
    outerPadding: { padding: 30 },
    sectionTitle: { fontSize: 10, marginBottom: 5, display: 'flex', flexDirection: 'column' },
    rowView: { fontSize: 9, display: 'flex', marginLeft: 5 },
    rowOdd: { backgroundColor: 'rgb(232, 232, 232)' },
    tableHeaders: { backgroundColor: '#d33c2e', color: '#fff', marginTop: -1, fontWeight: '700' },
    mainPointTitle: { fontSize: 10, marginLeft: 5, marginBottom: 2, marginTop: 5, display: 'flex', flexDirection: 'column' },
    mainPointValue: { width: '70%' },
    categoryPointList: { fontSize: 9, display: 'flex', flexDirection: 'column' },
    points: { marginLeft: 10 },
    columnContainer: { flexDirection: 'row', justifyContent: 'space-between' },
    column1: { width: '50%', paddingRight: 5,  },
    column2: { width: '50%',  paddingLeft: 5,    },
    border: { borderTopColor: 'grey', borderTopWidth: 1, marginBottom: 5, marginTop: 5 },
    step6Title: {fontSize: 10, marginBottom: 5, display: 'flex', flexDirection: 'column' },
    step6Row: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 5,
    },
  });

  const renderPoint = (data, label, key, isStep6 = false) => {
    if (typeof data === 'string') {
      return (
        <View key={key} style={pdfStyles.column1}>
          <Text style={isStep6 ? pdfStyles.step6Title : pdfStyles.mainPointTitle}>{label}</Text>
          <Text style={pdfStyles.rowView}>
            {data === 'yes' ? 'Ja' : data === 'no' ? 'Nein' : data}
          </Text>
        </View>
      );
    } else if (Array.isArray(data)) {
      return (
        <View key={key} style={pdfStyles.column1}>
          <Text style={pdfStyles.mainPointTitle}>{label}</Text>
          <View style={pdfStyles.categoryPointList}>
            {data.map((item, i) => (
              <View style={{ flexDirection: 'row', marginBottom: 1 }} key={i}>
                <Text style={{ marginLeft: 20, marginRight: 8 }}>•</Text>
                <Text>{item.label || item}</Text>
              </View>
            ))}
          </View>
        </View>
      );
    } else if (data?.preset || data?.custom) {
      return (
        <View key={key} style={pdfStyles.column1}>
          <Text style={pdfStyles.mainPointTitle}>{label}</Text>
          <View style={pdfStyles.categoryPointList}>
            {[...(data.preset || []), ...(data.custom || [])].map((obj, i) => (
              <View style={{ flexDirection: 'row', marginBottom: 1 }} key={i}>
                <Text style={{ marginLeft: 20, marginRight: 8 }}>•</Text>
                <Text>{obj.label}</Text>
              </View>
            ))}
          </View>
        </View>
      );
    }
    return null;
  };

  const renderStep = (stepData, stepLabels, isStep6 = false) => {
    if (!stepData) return null;
  
    const points = Object.keys(stepData);
    const midPoint = Math.ceil(points.length / 2);
  
    return (
      <View style={pdfStyles.section}>
        <View style={pdfStyles.columnContainer}>
          {points.slice(0, midPoint).map((point) => (
            <View key={point} style={pdfStyles.column1}>
              <Text style={pdfStyles.mainPointTitle}>{stepLabels[point] || ''}</Text>
              <View style={pdfStyles.rowView}>
                <Text>{renderValue(stepData[point])}</Text>
              </View>
            </View>
          ))}
          {points.slice(midPoint).map((point) => (
            <View key={point} style={pdfStyles.column2}>
              <Text style={pdfStyles.mainPointTitle}>{stepLabels[point] || ''}</Text>
              <View style={pdfStyles.rowView}>
                <Text>{renderValue(stepData[point])}</Text>
              </View>
            </View>
          ))}
        </View>
      </View>
    );
  };
  
  const renderValue = (value) => {
    if (typeof value === 'string') {
      return value === 'yes' ? 'Ja' : value === 'no' ? 'Nein' : value;
    } else if (Array.isArray(value)) {
      return value.map(item => item.label || item).join(', ');
    } else if (value?.preset || value?.custom) {
      return [...(value.preset || []), ...(value.custom || [])]
        .map(item => item.label)
        .join(', ');
    }
    return '';
  };

  return (
    <Document>
      <Page size="A4" style={pdfStyles.pageContainer}>
        {/* <View style={pdfStyles.logoContainer}>
          <Image style={pdfStyles.logo} source={Logo_Red_New} />
        </View> */}

        {data && (
          <View style={pdfStyles.outerPadding}>
            <View style={pdfStyles.documentcompanydata}>
              <Text>{companyInfo?.CompanyName}</Text>
              <Text>{companyInfo?.CompanyStreet} {companyInfo?.CompanyStreetNr}</Text>
              <Text>{companyInfo?.CompanyZIP} {companyInfo?.CompanyCity}</Text>
            </View>

            <View style={pdfStyles.documenttitle}>
              <Text>{t('detailedView.verzeichnis.title')} - {t('detailedView.entry')} {Object.values(data.step1)[0]}</Text>
            </View>

            <View style={pdfStyles.border}/>

            <View style={pdfStyles.section}>
              <View style={pdfStyles.columnContainer}>
                {Object.keys(data.step1).map((point, index) => (
                  <View key={point} style={index % 2 === 0 ? pdfStyles.column1 : pdfStyles.column2}>
                    <Text style={pdfStyles.mainPointTitle}>
                      {LABELS.step1[point] || ''}
                    </Text>
                    <View style={pdfStyles.rowView}>
                      <Text>
                        {data.step1[point]}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>

            <View style={pdfStyles.border}/>

            <View style={pdfStyles.section}>
              <View style={pdfStyles.columnContainer}>
                {[0, 1].map((colIdx) => (
                  <View
                    style={colIdx % 2 === 0 ? pdfStyles.column1 : pdfStyles.column2}
                    key={colIdx}
                  >
                    {Object.keys(data.step2).slice(colIdx * 4, (colIdx + 1) * 4).map((point, idx) => (
                      <View key={idx}>
                        <Text style={pdfStyles.mainPointTitle}>
                          {LABELS.step2[point]}
                        </Text>
                        <View style={pdfStyles.categoryPointList}>
                          {data.step2[point]?.preset?.map((obj, i) => (
                            <View style={{ flexDirection: 'row', marginBottom: 1 }} key={i}>
                              <Text style={{ marginLeft: 20, marginRight: 8 }}>•</Text>
                              <Text>{obj.label}</Text>
                            </View>
                          ))}
                          {data.step2[point]?.custom?.map((obj, i) => (
                            <View style={{ flexDirection: 'row', marginBottom: 1 }} key={i}>
                              <Text style={{ marginLeft: 20, marginRight: 8 }}>•</Text>
                              <Text>{obj.label}</Text>
                            </View>
                          ))}
                        </View>
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            </View>

            <View style={pdfStyles.border}/>

            <View style={pdfStyles.section}>
              <View style={pdfStyles.columnContainer}>
                {Object.keys(data.step3).map((point, index) => (
                  <View key={point} style={index % 2 === 0 ? pdfStyles.column1 : pdfStyles.column2}>
                    <Text style={pdfStyles.mainPointTitle}>
                      {LABELS.step3[point]}
                    </Text>
                    <View style={pdfStyles.categoryPointList}>
                      {data.step3[point]?.preset?.map((obj, i) => (
                        <View style={{ flexDirection: 'row', marginBottom: 1 }} key={i}>
                          <Text style={{ marginLeft: 20, marginRight: 8 }}>•</Text>
                          <Text>{obj.label}</Text>
                        </View>
                      ))}
                      {data.step3[point]?.custom?.map((obj, i) => (
                        <View style={{ flexDirection: 'row', marginBottom: 1 }} key={i}>
                          <Text style={{ marginLeft: 20, marginRight: 8 }}>•</Text>
                          <Text>{obj.label}</Text>
                        </View>
                      ))}
                    </View>
                  </View>
                ))}
              </View>
            </View>

            <View style={pdfStyles.border}/>

            <View style={pdfStyles.section}>
              <View style={pdfStyles.columnContainer}>
                {Object.keys(data.step4).slice(0, Math.ceil(Object.keys(data.step4).length / 2)).map((point) => (
                  <View key={point} style={pdfStyles.column1}>
                    <Text style={pdfStyles.mainPointTitle}>{LABELS.step4[point] || ''}</Text>
                    <View style={pdfStyles.rowView}>
                      <Text>{data.step4[point]}</Text>
                    </View>
                  </View>
                ))}
                {Object.keys(data.step4).slice(Math.ceil(Object.keys(data.step4).length / 2)).map((point) => (
                  <View key={point} style={pdfStyles.column2}>
                    <Text style={pdfStyles.mainPointTitle}>{LABELS.step4[point] || ''}</Text>
                    <View style={pdfStyles.rowView}>
                      <Text>{data.step4[point]}</Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>

            <View style={pdfStyles.border}/>

            <View style={pdfStyles.section}>
              <View style={pdfStyles.columnContainer}>
                {Object.keys(data.step5).map((point, idx) => (
                  <View  key={idx}>
                    <Text style={pdfStyles.mainPointTitle}>{LABELS.step5[point]}</Text>
                    <View style={pdfStyles.categoryPointList}>
                      {(data.step5[point]?.preset || []).concat(data.step5[point]?.custom || []).map((obj, i) => (
                        <View
                          style={{
                            flexDirection: 'row',
                            marginBottom: 1,
                          }}
                          key={i}
                        >
                          <Text style={{ marginLeft: 20, marginRight: 8 }}>•</Text>
                          <Text>{obj.label}</Text>
                        </View>
                      ))}
                    </View>
                  </View>
                ))}
              </View>
            </View>

            <View style={pdfStyles.border}/>

            {renderStep(data.step6, LABELS.step6, true)}


            <View style={pdfStyles.border}/>

            <View style={pdfStyles.section}>
              <View style={pdfStyles.columnContainer}>
                {Object.keys(data.step7).slice(0, Math.ceil(Object.keys(data.step7).length / 2)).map((point) => (
                  <View key={point} style={pdfStyles.column1}>
                    <Text style={pdfStyles.mainPointTitle}>{LABELS.step7[point] || ''}</Text>
                    <View style={pdfStyles.rowView}>
                      <Text>
                        {data.step7[point] === 'yes' ? 'Ja' : data.step7[point] === 'no' ? 'Nein' : data.step7[point]}
                      </Text>
                    </View>
                  </View>
                ))}
                {Object.keys(data.step7).slice(Math.ceil(Object.keys(data.step7).length / 2)).map((point) => (
                  <View key={point} style={pdfStyles.column2}>
                    <Text style={pdfStyles.mainPointTitle}>{LABELS.step7[point] || ''}</Text>
                    <View style={pdfStyles.rowView}>
                      <Text>
                        {data.step7[point] === 'yes' ? 'Ja' : data.step7[point] === 'no' ? 'Nein' : data.step7[point]}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>

            <View style={pdfStyles.border}/>

            <View style={pdfStyles.section}>
              <View style={pdfStyles.columnContainer}>
                {Object.keys(data.step8).slice(0, Math.ceil(Object.keys(data.step8).length / 2)).map((point) => (
                  <View key={point} style={pdfStyles.column1}>
                    <Text style={pdfStyles.mainPointTitle}>{LABELS.step8[point] || ''}</Text>
                    <View style={pdfStyles.rowView}>
                      <Text>
                        {data.step8[point] === 'yes' ? 'Ja' : data.step8[point] === 'no' ? 'Nein' : data.step8[point]}
                      </Text>
                    </View>
                  </View>
                ))}
                {Object.keys(data.step8).slice(Math.ceil(Object.keys(data.step8).length / 2)).map((point) => (
                  <View key={point} style={pdfStyles.column2}>
                    <Text style={pdfStyles.mainPointTitle}>{LABELS.step8[point] || ''}</Text>
                    <View style={pdfStyles.rowView}>
                      <Text>
                        {data.step8[point] === 'yes' ? 'Ja' : data.step8[point] === 'no' ? 'Nein' : data.step8[point]}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>

            <View style={pdfStyles.border}/>
            <View style={pdfStyles.documentsubtitle}>
              <Text>{t('detailedView.dateCreated')}: {data.dateCreated.replace(/-/g, '.')}</Text>
              <Text>{t('detailedView.lastEdited')}: {data.lastEdited.replace(/-/g, '.')}</Text>
            </View>

          </View>
        )}
      </Page>
    </Document>
  );
};

export { DetailedViewPdf };