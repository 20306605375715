import React from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { StepsNavigation } from 'components/StepsNavigation';
import { TomsStart } from './TomsStart';
import { TomsFormular } from './TomsFormular';
import { MobileNavbar, Navbar } from 'components';
import { useApi } from 'services/axios';
import { useTranslation } from 'react-i18next';
import TipBar from 'components/TipBar';


import styles from './styles.module.css';

const Toms = () => {
  const { id } = useParams();
  const { api, isApiReady } = useApi();
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(id ? 1 : 0);
  const [entry, setEntry] = React.useState(null);

  const marks = {
    1: `${t('step')} \n 1`,
    2: `${t('step')} \n 2`,
    3: `${t('step')} \n 3`,
    4: `${t('step')} \n 4`,
    5: `${t('step')} \n 5`,
    6: `${t('step')} \n 6`,
    7: `${t('step')} \n 7`,
    8: `${t('step')} \n 8`,
    9: `${t('step')} \n 9`,
    10: `${t('step')} \n 10`,
    11: `${t('step')} \n 11`,
    12: `${t('step')} \n 12`,
  };

  const methods = useForm({ mode: 'onChange' });

  const handleNext = async () => {
    await methods.trigger();

    if (Object.keys(methods.formState.errors)?.length > 0) {
      return;
    }

    setActiveStep((prevStep) => prevStep + 1);
    methods.clearErrors();
  };

  const handleBack = async () => {
    if (activeStep === 1) {
      return navigate('/Programme');
    }

    setActiveStep((prevStep) => prevStep - 1);
  };

  const fetchEntry = async () => {
    try {
      const entryResponse = await api.get(`/toms_alldata/${id}`);

      setEntry(entryResponse.data);
    } catch (err) {
      console.error('Error fetching entry: ', err);
    }
  };

  const submitForm = async (formData) => {
    try {
      const postResponse = await api.post('/toms_alldata', formData);

      console.log('post response: ', postResponse);
    } catch (err) {
      console.error('Error submitting form: ', err);
    }
  };

  const submitEditForm = async (formData) => {
    try {
      const postResponse = await api.post(`/toms_alldata/${id}`, formData);

      console.log('post response: ', postResponse);
    } catch (err) {
      console.error('Error submitting form: ', err);
    }
  };

  const onSubmit = (data) => {
    if (
      id &&
      (activeStep === 12 || sessionStorage.getItem(`saveTomsEntry-${id}`))
    ) {
      submitEditForm(data);

      sessionStorage.removeItem(`saveTomsEntry-${id}`);
      return;
    }

    submitForm(data);
  };

  React.useEffect(() => {
    if (id && !entry && isApiReady) {
      fetchEntry();
    }
  }, [id, isApiReady]);

  React.useEffect(() => {
    if (entry) {
      methods.reset(entry);
    }
  }, [entry]);

  React.useEffect(() => {
    if (methods.formState.isSubmitSuccessful) {
      if (id) {
        toast.success('Eintrag erfolgreich bearbeitet');
      } else {
        toast.success('Eintrag erfolgreich erstellt');
      }

      setTimeout(() => {
        navigate('/TomsOverview');
      }, 500);
    }
  }, [methods.formState]);

  return (
    <div className="wrapper" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
    <TipBar />
    <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
      <Navbar />
      <MobileNavbar />
      <div id="content" className={styles.content}>
        <div
          className="container"
          style={{
            width: '100%',
            padding: '0',
          }}
        >
          <div
            className="row topmargin100"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className="card col-md-8">
              <FormProvider {...methods}>
                <form
                  onSubmit={methods.handleSubmit(onSubmit)}
                  className={styles.form}
                  id="toms-form"
                >
                  {activeStep === 0 && <TomsStart next={handleNext} />}
                  {activeStep >= 1 && (
                    <TomsFormular
                      activeStep={activeStep}
                      next={handleNext}
                      back={handleBack}
                    />
                  )}
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
        {id && (
          <StepsNavigation
            step={activeStep}
            setStep={setActiveStep}
            errors={methods.formState.errors}
            marks={marks}
            min={1}
            max={12}
            customColor="#f7b731"
          />
        )}
      </div>
    </div>
    </div>
  );
};

export { Toms };
