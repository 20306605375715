import React from 'react';
import { Input } from 'components/Input';
import { useFormContext, Controller } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';

const Verzeichnis_Step1 = () => {
  const { control, formState } = useFormContext();
  const validation = useValidation();
  const { t } = useTranslation('common');
  const INPUTS = [
    {
      label: t('verzeichnis.steps.1.inputs.designation'),
      name: 'bezeichnung',
      id: 'verzeichnis-bezeichnung',
      tooltipText: t('verzeichnis.steps.1.inputs.designationTooltip'),
    },
    {
      label: t('verzeichnis.steps.1.inputs.purpose'),
      name: 'zweck',
      id: 'verzeichnis-zweck',
      tooltipText: t('verzeichnis.steps.1.inputs.purposeTooltip'),
    },
  ];

  return (
    <div className="card-body">
      <div className={styles.steptext}>
        <h4>Bezeichnung und Zweck</h4>
      </div>
      <div className={styles.verzeichnisInputsWrapper}>
        {INPUTS.map((input) => (
          <Controller
            key={input.id}
            control={control}
            name={`step1.${input.name.toLowerCase()}`}
            rules={{ ...validation.required }}
            defaultValue=""
            render={({ field }) => {
              return (
                <Input
                  {...input}
                  {...field}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={formState.errors?.step1?.[input.name.toLowerCase()]}
                />
              );
            }}
          />
        ))}
      </div>
    </div>
  );
};

export { Verzeichnis_Step1 };
