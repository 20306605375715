import React from 'react';

import { Input } from 'components/Input';
import { useFormContext, Controller } from 'react-hook-form';
import { useValidation } from 'helpers/validation';
import { RadioGroup } from 'components/RadioGroup';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';

const Verzeichnis_Step7 = () => {
  const { control, watch, unregister, formState } = useFormContext();
  const { t } = useTranslation('common');
  const validation = useValidation();
  const bearbeitungsreglement = watch('step7.bearbeitungsreglement');
  const datenschutz = watch('step7.datenschutz');
  const options = t('radioOptions', { returnObjects: true });

  React.useEffect(() => {
    if (bearbeitungsreglement === 'no') {
      unregister('step7.reglementLink');
    }

    if (datenschutz === 'no') {
      unregister('step7.linkDatenschutz');
    }
  }, [bearbeitungsreglement, datenschutz]);

  return (
    <div className="card-body">
      <h4>Zusatzdokumentation</h4>
      <div className={styles.steptext}>
        {t('verzeichnis.steps.7.description')}
      </div>
      <div className={styles.verzeichnisInputsWrapper}>
        <Controller
          control={control}
          name="step7.bearbeitungsreglement"
           /*       KEVIN B hier wäre es gut, wenn man die Dokumente gleich aus der Liste der Dokumente auswählen könnte */
          rules={{ ...validation.required }}
          render={({ field }) => (
            <RadioGroup
              label={t('verzeichnis.steps.7.inputs.processingRegulations')}
              name="bearbeitungsreglement"
              options={options}
              error={formState?.errors?.step7?.bearbeitungsreglement}
              {...field}
            />
          )}
        />

        {bearbeitungsreglement === 'yes' && (
          <Controller
            name="step7.reglementLink"
            control={control}
            render={({ field }) => (
              <Input
                label={t('verzeichnis.steps.7.inputs.reglementLink')}
                id="verzeichnis-reglementLink"
                error={formState.errors?.step7?.reglementLink}
                tooltipText="Hier können Sie die/den URL/Link zum Bearbeitungsreglement angeben oder sonst einen Kommentar hinterlassen. wo das Bearbeitungsreglement zu finden ist."
                {...field}
              />
            )}
          />
        )}

        <Controller
          name="step7.datenschutz"
          control={control}
          rules={{ ...validation.required }}
          render={({ field }) => (
            <RadioGroup
              label={t('verzeichnis.steps.7.inputs.impactAssessment')}
              name="Datenschutz-Folgenabschätzung"
              options={options}
              error={formState.errors?.step7?.datenschutz}
              {...field}
            />
          )}
        />

        {datenschutz === 'yes' && (
          <Controller
            name="step7.linkDatenschutz"
            control={control}
            render={({ field }) => (
              <Input
                label={t('verzeichnis.steps.7.inputs.assessmentLink')}
                id="verzeichnis-datenschutzLink"
                error={formState.errors?.step7?.linkDatenschutz}
                tooltipText="Hier können Sie die/den URL/Link zur Datenschutz-Folgenabschätzung angeben oder sonst einen Kommentar hinterlassen. wo die Datenschutz-Folgenabschätzung zu finden ist."
                {...field}
              />
            )}
          />
        )}
      </div>
    </div>
  );
};

export { Verzeichnis_Step7 };
