import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useApi } from 'services/axios';
import { Navbar, Loader, MobileNavbar } from 'components';
import { useTranslation } from 'react-i18next';
import { DetailedViewPdf } from './detailedViewPdf';
import TipBar from 'components/TipBar';
import styles from './styles.module.css';
import { TITLES } from 'Programme/Toms/TomsDetailedView';
import { PURPOSES, DATA_ABROAD } from './constants';


const DsaDetailedView = () => {
  const { id } = useParams();
  const [data, setData] = React.useState(null);
  const [selectedTomsData, setSelectedTomsData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [companyData, setCompanyData] = React.useState(null);
  const { api } = useApi();
  const { t } = useTranslation('common');
  const contentRef = React.useRef(null);
  

  const fetchEntry = async () => {
    try {
      console.log('Fetching entry data...');
      const entryResponse = await api.get(`/dsa_alldata/${id}`);
      const companyResponse = await api.get(`/companydata`);
  
      console.log('Entry data received:', entryResponse.data);
      console.log('Company data received:', companyResponse.data[0]);
  
      setError(null);
      setData(entryResponse.data);
      setCompanyData(companyResponse.data[0]);
    } catch (err) {
      console.error('Error fetching entry: ', err);
      setError(err);
    }
  };

  const fetchToms = async () => {
    try {
      console.log('Fetching TOMS data...');
      const tomsResponse = await api.get(`/toms_alldata/${data.step6.selectedTomsId}`);
  
      console.log('TOMS data received:', tomsResponse.data);
      setSelectedTomsData(tomsResponse.data);
    } catch (err) {
      console.error('Error fetching TOMS entry: ', err);
      setError(err);
    }
  };

  const copyAsPlainText = () => {
    if (contentRef.current) {
      const content = contentRef.current.innerText;
      navigator.clipboard.writeText(content).then(() => {
        alert('Text wurde als Klartext in die Zwischenablage kopiert!');
      });
    } else {
      alert('Inhalt konnte nicht gefunden werden.');
    }
  };

  const copyAsHtml = () => {
    if (contentRef.current) {
      const content = contentRef.current.innerHTML;
      navigator.clipboard.writeText(content).then(() => {
        alert('Text wurde als HTML in die Zwischenablage kopiert!');
      });
    } else {
      alert('Inhalt konnte nicht gefunden werden.');
    }
  };

  const CopyButton = ({ copyAsPlainText, copyAsHtml }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const handleClick = (action) => {
      action();
      setIsOpen(false);
    };
  
    return (
      <div className={styles.dropdownContainer}>
        <button 
          className="btn btn-default"
          onClick={() => setIsOpen(!isOpen)}
        >
          Kopieren ▼
        </button>
        {isOpen && (
          <div className={styles.dropdownMenu}>
            <button onClick={() => handleClick(copyAsPlainText)}>Klartext</button>
            <button onClick={() => handleClick(copyAsHtml)}>HTML</button>
          </div>
        )}
      </div>
    );
  };
  
  

  React.useEffect(() => {
    if (api && !data && !companyData && !error) {
      fetchEntry();
    }
  }, [api]);

  React.useEffect(() => {
    console.log('useEffect for fetchToms triggered');
    if (data && data.step6 && data.step6.selectedTomsId && !selectedTomsData) {
      fetchToms();
    }
  }, [data, selectedTomsData]);

  if (error)
    return (
      <div className="wrapper">
        <div id="content">
          <Link to="/VerzeichnisOverview" className={styles.detailedViewLink}>
            <button className="btn btn-default">
              {t('detailedView.backToOverview')}
            </button>
          </Link>
          <h2>{t('detailedView.notFound')}</h2>
        </div>
      </div>
    );

  if (!data)
    return (
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    );

    const { companyInfo } = {
    companyInfo: data.step1.usesDefaultCompany ? companyData : data.step1.customCompany,
  };  


  return (
    <div className="wrapper" style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100vh' }}>
    <TipBar />
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
      <Navbar />
      <MobileNavbar />

      <div
        className={`${styles.detailedViewWrapper} morph_variation1`}
        id="content"
      >
        <div className={styles.detailedViewHeadingWrapper}>
          <div className={styles.detailedViewTitleWrapper}>
            <Link to="/DsaOverview" className={styles.detailedViewLink}>
              <button className="btn btn-default">
                {t('detailedView.backToOverview')}
              </button>
            </Link>
          </div>
          

          <div className={styles.detailedViewLinks}>
            <Link to={`/Dsa/${id}`} className={styles.detailedViewLink}>
              <button className="btn btn-primary accent-orange-bg">
                {t('detailedView.edit')}
              </button>
            </Link>
            <CopyButton  copyAsPlainText={copyAsPlainText} copyAsHtml={copyAsHtml} />
            <PDFDownloadLink
              document={
                <DetailedViewPdf
                  data={{
                    ...data,
                    id,
                    companyInfo: data.step1.usesDefaultCompany
                      ? companyData
                      : data.step1.customCompany,
                  }}
                  selectedTomsData={selectedTomsData}
                />
              }
              fileName={`Datenschutzerklärung`}
            >
              {({ loading }) => (
                <button className="btn btn-default">
                  {loading
                    ? 'Loading document...'
                    : t('detailedView.downloadPDF')}
                </button>
              )}
            </PDFDownloadLink>
          </div>
        </div>
        <hr />
        <div className={styles.pdfLikeContent} ref={contentRef}>
                {/* Titel */}
            <h2 className={styles.documentTitle}>Datenschutzerklärung {companyInfo?.CompanyName}</h2>
            {/* Einleitung */}
            <p>
              In dieser Datenschutzerklärung erläutern wir, die {companyInfo?.CompanyName} (nachfolgend wir oder uns), wie wir
              Personendaten erheben und sonst bearbeiten. Das ist keine abschliessende Beschreibung; allenfalls regeln andere
              Datenschutzerklärungen oder allgemeine Geschäftsbedingungen, Teilnahmebedingungen und ähnliche Dokumente spezifische
              Sachverhalte. Unter Personendaten werden alle Angaben verstanden, die sich auf eine bestimmte oder bestimmbare Person
              beziehen.<br></br>
              Wenn Sie uns Personendaten anderer Personen (z.B. Familienmitglieder, Daten von Arbeitskollegen) zur Verfügung stellen, stellen Sie bitte sicher, dass diese Personen die vorliegende Datenschutzerklärung kennen und teilen Sie uns deren Personendaten nur mit, wenn Sie dies dürfen und
              wenn diese Personendaten korrekt sind.
            </p>

            {/* Rechtsgrundlage */}
            <h3>Rechtsgrundlage</h3>
            <p>
              {data.step1.law.value === 1    
                ? 'Diese Datenschutzerklärung ist auf die Anforderungen des Schweizer Datenschutzgesetzes (DSG) ausgelegt.'
                : data.step1.law.value === 2
                ? 'Diese Datenschutzerklärung ist auf die Anforderungen der EU-Datenschutz-Grundverordnung (DSGVO) ausgelegt.'
                : "Diese Datenschutzerklärung ist auf die Anforderungen der EU-Datenschutz-Grundverordnung (DSGVO) und des Schweizer Datenschutzgesetzes (DSG) ausgelegt. Ob und inwieweit diese Gesetze anwendbar sind, hängt jedoch vom Einzelfall ab."
                
              }
            </p>

            {/* Verantwortlicher */}
            <h3>Verantwortlicher</h3>
            <p>
              Verantwortlich für die Datenbearbeitungen, die wir hier beschreiben, ist die {companyInfo?.CompanyName}. 
              Wenn Sie datenschutzrechtliche Anliegen haben, können Sie uns diese an folgende Kontaktadresse mitteilen:
              <br /><br />
              {companyInfo?.CompanyName}<br />
              {companyInfo?.CompanyStreet}<br />
              {companyInfo?.CompanyZIP} {companyInfo?.CompanyCity}<br />
              Telefon: {companyInfo?.CompanyPhone}.
            </p>

            {/* Erhebung */}
            <h3>Erhebung und Bearbeitung von Personendaten</h3>
            <p>
            Wir bearbeiten in erster Linie die Personendaten, die wir im Rahmen unserer Geschäftsbeziehung mit unseren Kunden und anderen Geschäftspartnern von diesen und weiteren daran beteiligten Personen erhalten oder die wir beim Betrieb unserer Websites, Apps und weiteren Anwendungen von deren Nutzern erheben.
            Soweit dies erlaubt ist, entnehmen wir auch aus öffentlich zugänglichen Quellen (z.B. Betrei- bungsregister, Grundbücher, Handelsregister, Presse, Internet) gewisse Daten oder erhalten solche von Behörden und sonstigen Dritten. Nebst den Daten von Ihnen, die Sie uns direkt geben, umfassen die Kategorien von Personendaten, die wir von Dritten über Sie erhalten, insbesondere Angaben aus öffentlichen Registern, Angaben, die wir im Zusammenhang mit behördlichen und gerichtlichen Verfahren erfahren, Angaben im Zusammenhang mit ihren beruflichen Funktionen und Aktivitäten (damit wir z.B. mit Ihrer Hilfe Geschäfte mit Ihrem Arbeitgeber abschliessen und abwickeln können), Angaben über Sie in Korrespondenz und Besprechungen mit Dritten, Bonität- sauskünfte (soweit wir mit Ihnen persönlich Geschäfte abwickeln), Angaben über Sie, die uns Personen aus Ihrem Umfeld (Familie, Berater, Rechtsvertreter, etc.) geben, damit wir Verträge mit Ihnen oder unter Einbezug von Ihnen abschliessen oder abwickeln können (z.B. Referenzen, Ihre
            Adresse für Lieferungen, Vollmachten, Angaben zur Einhaltung gesetzlicher Vorgaben wie etwa der Geldwäschereibekämpfung und Exportrestriktionen, Angaben von Banken, Versicherungen, Vertriebs- und anderen Vertragspartnern von uns zur Inanspruchnahme oder Erbringung von Leistungen durch Sie (z.B. erfolgte Zahlungen, erfolgte Käufe)), Angaben aus Medien und Internet zu Ihrer Person (soweit dies im konkreten Fall angezeigt ist, z.B. im Rahmen einer Bewerbung, Presseschau, Marketing/Verkauf, etc.), Ihre Adressen und ggf. Interessen und weitere soziode- mographische Daten (für Marketing), Daten im Zusammenhang mit der Benutzung der Website (z.B. IP-Adresse, MAC-Adresse des Smartphones oder Computers, Angaben zu Ihrem Gerät und Einstellungen, Cookies, Datum und Zeit des Besuchs, abgerufene Seiten und Inhalte,benutzte Funktionen, verweisende Website, Standortangaben).
            </p>

            {/* Zweck */}
            <h3>Zweck der Datenbearbeitungen</h3>
            <p>
            Wir verwenden die von uns erhobenen Personendaten in erster Linie, um unsere Verträge mit
            unseren Kunden und Geschäftspartnern abzuschliessen und abzuwickeln, so insbesondere im
            Rahmen der Gastro Services und den Einkauf von Produkten und Dienstleistungen von unseren
            Lieferanten und Subunternehmern, sowie um unseren gesetzlichen Pflichten im In- und Ausland
            nachzukommen. Wenn Sie für einen solchen Kunden oder Geschäftspartner tätig sind, können Sie
            in dieser Funktion mit Ihren Personendaten natürlich ebenfalls davon betroffen sein.
            Darüber hinaus bearbeiten wir Personendaten von Ihnen und weiteren Personen, soweit erlaubt
            und es uns als angezeigt erscheint, auch für folgende Zwecke, an denen wir (und zuweilen auch
            Dritte) ein dem Zweck entsprechendes berechtigtes Interesse haben:
            </p>
            <ul>
              {data.step3.purpose?.preset?.map((point, i) => (
                <li key={i}>{PURPOSES[point.value - 1]}</li>
              ))}
            </ul>

            {/* Ausland */}
            <h3>Datenbekanntgabe</h3>
            <p>
              {data.step4a.abroadRadio === 'no'
                ? 'Wir übermitteln keine Daten an Dritte. Sobald dies der Fall werden sollte, werden wir an dieser Stelle darüber informieren.'
                : 'Wir geben im Rahmen unserer geschäftlichen Aktivitäten und der hier erwähnten Zwecke der Bearbeitung, soweit erlaubt und es uns als angezeigt erscheint, auch Dritten bekannt. Sei es, weil sie diese für uns bearbeiten oder für ihre eigenen Zwecke verwenden. Dabei handelt es sich insbesondere um folgende Stellen:'
              }
            </p>
            {data.step4a.abroadRadio === 'yes' && (
              <ul>
                {data.step4a.abroadDropdown?.preset?.map((point, i) => (
                  <li key={i}>{DATA_ABROAD[point.value - 1]}</li>
                ))}
              </ul>
            )}

            {/* Dauer */}
            <h3>Dauer der Aufbewahrung</h3>
            <p>
            Wir verarbeiten und speichern Ihre Personendaten, solange es für die Erfüllung unserer vertraglichen und gesetzlichen Pflichten oder sonst die mit der Bearbeitung verfolgten Zwecke erforderlich ist, d.h. also zum Beispiel für die Dauer der gesamten Geschäftsbeziehung (von der Anbahnung,
            Abwicklung bis zur Beendigung eines Vertrags) sowie darüber hinaus gemäss den gesetzlichen
            Aufbewahrungs- und Dokumentationspflichten. Dabei ist es möglich, dass Personendaten für die
            Zeit aufbewahrt werden, in der Ansprüche gegen unser Unternehmen geltend gemacht werden
            können und soweit wir anderweitig gesetzlich dazu verpflichtet sind oder berechtigte Geschäftsinteressen dies erfordern (z.B. für Beweis- und Dokumentationszwecke). Sobald Ihre Personendaten
            für die oben genannten Zwecke nicht mehr erforderlich sind, werden sie grundsätzlich und soweit
            möglich gelöscht oder anonymisiert.
            </p>

            {/* Datensicherheit KEVIN: FUNKTION NOCH Einbauen (TOMS anzeigen oder nur Standardtext*/}
            <h3>Datensicherheit</h3>
              <p>
                Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst und haben technische und organisatorische Massnahmen getroffen, um die Sicherheit Ihrer Daten zu gewährleisten. Insbesondere speichern und verarbeiten wir unsere Daten unter hohen Sicherheitsstandards.
              </p>
              {(data.step6?.showToms === 'toms' || data.step6?.selectedTomsId) && selectedTomsData ? (
                <>
                  <p>Unter anderem haben wir folgende Massnahmen umgesetzt:</p>
                  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                    {Object.entries(selectedTomsData)
                      .filter(([key]) => key.includes('step'))
                      .map(([step, stepData], idx) => (
                        <div key={step} style={{ width: '48%', marginBottom: '10px' }}>
                          <h4 style={{ fontSize: '14px', fontWeight: 'bold', marginTop: '2px', marginBottom: '2px' }}>
                            {TITLES[idx]}
                          </h4>
                          <ul style={{ marginLeft: '20px', marginBottom: '2px' }}>
                            {Object.values(stepData).flatMap((points) =>
                              Array.isArray(points)
                                ? points.map((obj, i) => (
                                    <li key={i} style={{ marginBottom: '5px' }}>
                                      {obj.label}
                                    </li>
                                  ))
                                : null
                            )}
                          </ul>
                        </div>
                      ))}
                  </div>
                </>
              ) : (
                <p></p>
              )}

            {/* Betroffenenrechte */}
            <h3>Ihre Rechte als betroffene Person</h3>
            <p dangerouslySetInnerHTML={{
              __html: 
                data.step1.law.value === 1    
                ? `Sie haben das Recht:
                    <br />- auf Auskunft (Art. 25 DSG),
                    <br />- auf Datenherausgabe und -übertragung (Art. 28 DSG),
                    <br />- auf Berichtigung (Art. 32 DSG),
                    <br />- und auf Klagen zum Schutz Ihrer Persönlichkeit (Art. 32 DSG).
                    <br /> <br />Die Ausübung solcher Rechte setzt in der Regel voraus, dass Sie Ihre Identität eindeutig nachweisen (z.B. durch eine Ausweiskopie, wo Ihre Identität sonst nicht klar ist bzw. verifiziert werden kann). Zur Geltendmachung Ihrer Rechte können Sie uns unter unserer angegebenen Adresse kontaktieren.`
                : data.step1.law.value === 2
                ? `Sie haben im Rahmen der DSGVO das Recht auf Auskunft, Berichtigung, Löschung, das Recht auf Einschränkung der Datenbearbeitung und sonst dem Widerspruch gegen unsere Datenbearbeitungen, insbesondere jener für Zwecke des Direktmarketings und weiterer berechtigter Interessen an der Bearbeitung sowie auf Herausgabe gewisser Personendaten zwecks Übertragung an eine andere Stelle (sog. Datenportabilität). 
                  <br /><br />Bitte beachten Sie aber, dass wir uns vorbehalten, unsererseits die gesetzlich vorgesehenen Einschränkungen geltend zu machen, etwa wenn wir zur Aufbewahrung oder Bearbeitung gewisser Daten verpflichtet sind, daran ein überwiegendes Interesse haben (soweit wir uns darauf berufen dürfen) oder sie für die Geltendmachung von Ansprüchen benötigen. Falls für Sie Kosten anfallen, werden wir Sie vorab informieren. Beachten Sie, dass die Ausübung dieser Rechte im Konflikt zu vertraglichen Abmachungen stehen kann und dies Folgen wie z.B. die vorzeitige Vertragsauflösung oder Kostenfolgen haben kann. Wir werden Sie diesfalls vorgängig informieren, wo dies nicht bereits vertraglich geregelt ist. 
                  <br /><br />Die Ausübung solcher Rechte setzt in der Regel voraus, dass Sie Ihre Identität eindeutig nachweisen (z.B. durch eine Ausweiskopie, wo Ihre Identität sonst nicht klar ist bzw. verifiziert werden kann). Zur Geltendmachung Ihrer Rechte können Sie uns unter der angegebenen Adresse kontaktieren. Jede betroffene Person hat überdies das Recht, ihre Ansprüche gerichtlich durchzusetzen oder bei der zuständigen Datenschutzbehörde eine Beschwerde einzureichen.`
                : `Sie haben im Rahmen des auf Sie anwendbaren Datenschutzrechts und soweit darin vorgesehen das Recht auf Auskunft, Berichtigung, Löschung, das Recht auf Einschränkung der Datenbearbeitung und sonst dem Widerspruch gegen unsere Datenbearbeitungen, insbesondere jener für Zwecke des Direktmarketings und weiterer berechtigter Interessen an der Bearbeitung sowie auf Herausgabe gewisser Personendaten zwecks Übertragung an eine andere Stelle (sog. Datenportabilität). 
                  <br /><br />Bitte beachten Sie aber, dass wir uns vorbehalten, unsererseits die gesetzlich vorgesehenen Einschränkungen geltend zu machen, etwa wenn wir zur Aufbewahrung oder Bearbeitung gewisser Daten verpflichtet sind, daran ein überwiegendes Interesse haben (soweit wir uns darauf berufen dürfen) oder sie für die Geltendmachung von Ansprüchen benötigen. Falls für Sie Kosten anfallen, werden wir Sie vorab informieren. Beachten Sie, dass die Ausübung dieser Rechte im Konflikt zu vertraglichen Abmachungen stehen kann und dies Folgen wie z.B. die vorzeitige Vertragsauflösung oder Kostenfolgen haben kann. Wir werden Sie diesfalls vorgängig informieren, wo dies nicht bereits vertraglich geregelt ist. 
                  <br /><br />Die Ausübung solcher Rechte setzt in der Regel voraus, dass Sie Ihre Identität eindeutig nachweisen (z.B. durch eine Ausweiskopie, wo Ihre Identität sonst nicht klar ist bzw. verifiziert werden kann). Zur Geltendmachung Ihrer Rechte können Sie uns unter der angegebenen Adresse kontaktieren. Jede betroffene Person hat überdies das Recht, ihre Ansprüche gerichtlich durchzusetzen oder bei der zuständigen Datenschutzbehörde eine Beschwerde einzureichen. Die zuständige Datenschutzbehörde der Schweiz ist der Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte (http://www.edoeb.admin.ch).`
            }}>
            </p>



            {/* Änderungen */}
            <h3>Änderungen</h3>
            <p>
              Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung anpassen. Es gilt die jeweils aktuelle, auf unserer Website publizierte Fassung. Soweit die Datenschutzerklärung Teil einer Vereinbarung mit Ihnen ist, werden wir Sie im Falle einer Aktualisierung über die Änderung per E-Mail oder auf andere geeignete Weise informieren.
            </p>

            {/* Letzte Bearbeitung */}
            <p>Stand: {data.lastEdited.replace(/-/g, '.')}</p>
          </div>
      </div>
    </div>
    </div>
  );
};

export { DsaDetailedView };
