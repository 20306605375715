import React from 'react';
import { FormField } from '../FormField';

import styles from './styles.module.css';

const RadioGroup = ({
  onChange,
  name,
  options,
  label,
  value,
  error,
  customColor,
}) => {
  return (
    <FormField error={error}>
      <div className={styles.radioGroupWrapper}>
        <label className={styles.radioGroupLabel} style={{ marginBottom: 0 }}>
          {label}
        </label>
        <div className={styles.optionsWrapper}>
          {options.map((option) => (
            <label
              className={styles.radioOption}
              key={option.value}
              style={{ marginBottom: 0 }}
            >
              <input
                type="radio"
                value={option.value}
                checked={value === option.value}
                className={styles.radioInput}
                name={name}
                style={{ margin: 0, accentColor: customColor }}
                onChange={(e) => onChange(e.target.value)}
              />
              {option.label}
            </label>
          ))}
        </div>
      </div>
    </FormField>
  );
};

export { RadioGroup };
