import React from 'react';
import { Page,Text,View,Document,StyleSheet,Image } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { DATA_ABROAD, PURPOSES } from './constants';
import { TITLES } from 'Programme/Toms/TomsDetailedView';
// import { Font } from '@react-pdf/renderer';

// Font.register({
//   family: 'Arial',
//   src: '/path/to/Arial.ttf'
// }); FÜR ARIAL, SCHRIFT MUSS NOCH HINTERLEGT WERDEN DAFÜR


const DetailedViewPdf = ({ data, selectedTomsData }) => {
  const { companyInfo } = data;
  const { t } = useTranslation('common');

  const pdfStyles = StyleSheet.create({
    pageContainer: { paddingVertical: 40, paddingHorizontal: 20, /* fontFamily: 'Arial' */},
    outerPadding: { padding: 40 }, 
    documentcompanydata: { fontSize: 11, textAlign: 'left', padding: 2, marginBottom: 45,},
    documenttitle: { fontSize: 14, textAlign: 'left', fontWeight: 'bold', marginBottom: 20 },
    section: { display: 'flex', flexDirection: 'column', marginBottom: 5 },
    sectionTitle: { fontSize: 12, fontWeight: 'bold', marginTop: 15, marginBottom: 5},
    sectionText: { fontSize: 11, lineHeight: 1.2 },
    section: {display: 'flex',flexDirection: 'column'},
    twoColumnContainer: {      flexDirection: 'row',      flexWrap: 'wrap',      justifyContent: 'space-between',    },
    column: {width: '48%',marginBottom: 10,},
    tomsTitle: {fontSize: 12,fontWeight: 'bold',marginBottom: 5,},
    tomsList: {marginLeft: 10,},
    tomsListItem: {fontSize: 10,marginBottom: 2,},  
  });

  return (
    <Document>
      <Page size="A4" style={pdfStyles.pageContainer}>
        {data && (
          <View style={pdfStyles.outerPadding}>
          
          {/* Firmenangaben */}
            <View style={pdfStyles.documentcompanydata}>
                <Text>{companyInfo?.CompanyName}</Text>
                <Text>
                  {companyInfo?.CompanyStreet}
                </Text>
                <Text>
                  {companyInfo?.CompanyZIP} {companyInfo?.CompanyCity}
                </Text>
              </View>      
            
          {/* Titel */}
            <View style={pdfStyles.documenttitle}>
              <Text>Datenschutzerklärung {companyInfo?.CompanyName}</Text>
            </View>

          {/* Einleitung */}
          <View style={pdfStyles.sectionTitle}>
              <Text>Einleitung</Text>
            </View>
            <View>
              <Text style={pdfStyles.sectionText}>
                In dieser Datenschutzerklärung erläutern wir, die{' '}
                {companyInfo?.CompanyName} (nachfolgend wir oder uns), wie wir
                Personendaten erheben und sonst bearbeiten. Das ist keine
                abschliessende Beschreibung; allenfalls regeln andere
                Datenschutzerklärungen oder allgemeine Geschäftsbedingungen,
                Teilnahmebedingungen und ähnliche Dokumente spezifische
                Sachverhalte. Unter Personendaten werden alle Angaben
                verstanden, die sich auf eine bestimmte oder bestimmbare Person
                beziehen. 
                {'\n'}
                Wenn Sie uns Personendaten anderer Personen (z.B.
                Familienmitglieder, Daten von Arbeitskollegen) zur Verfügung
                stellen, stellen Sie bitte sicher, dass diese Personen die
                vorliegende Datenschutzerklärung kennen und teilen Sie uns deren
                Personendaten nur mit, wenn Sie dies dürfen und wenn diese
                Personendaten korrekt sind.
              </Text>
            </View>

          {/* Rechtsgrundlage */}
            <View style={pdfStyles.sectionTitle}>
                <Text>Rechtsgrundlage</Text>
              </View>
            <View>
              <Text style={pdfStyles.sectionText}>
                {data.step1.law.value === 1    
                  ? 'Diese Datenschutzerklärung ist auf die Anforderungen des Schweizer Datenschutzgesetzes (DSG) ausgelegt.'
                  : data.step1.law.value === 2
                  ?   'Diese Datenschutzerklärung ist auf die Anforderungen der EU-Datenschutz-Grundverordnung (DSGVO) ausgelegt.'
                  : "Diese Datenschutzerklärung ist auf die Anforderungen der EU-Datenschutz-Grundverordnung (DSGVO) und des Schweizer Datenschutzgesetzes (DSG) ausgelegt. Ob und inwieweit diese Gesetze anwendbar sind, hängt jedoch vom Einzelfall ab."
                }
              </Text>
            </View>

          {/* Verantwortlicher */}
              <View style={pdfStyles.sectionTitle}>
                <Text>Verantwortlicher</Text>
              </View>
                <View>
                  <Text style={pdfStyles.sectionText}>
                    Verantwortlich für die Datenbearbeitungen, die wir hier
                    beschreiben, ist die {companyInfo?.CompanyName}. Wenn Sie
                    datenschutzrechtliche Anliegen haben, können Sie uns diese an
                    folgende Kontaktadresse mitteilen: 
                    {'\n'}
                    {'\n'}{companyInfo?.CompanyName}
                    {'\n'}{companyInfo?.CompanyStreet}
                    {'\n'}{companyInfo?.CompanyZIP}{' '}{companyInfo?.CompanyCity}
                    {'\n'}Telefon: {companyInfo?.CompanyPhone}.
                    {'\n'}
                  </Text>
                </View>

          {/* Erhebung KEVIN KANN MAN HIER DIE ANGWÄHLTENDATEN UND KATEGORIEN VON BEARBEITZUNGSVERZEICHNIS EINBLENDEN? */}
          <View style={pdfStyles.sectionTitle}>
                <Text>Erhebung und Bearbeitung von Personendaten </Text>
              </View>
                <View>
                  <Text style={pdfStyles.sectionText}>
                  Wir bearbeiten in erster Linie die Personendaten, die wir im Rahmen unserer 
                  Geschäftsbeziehung mit unseren Kunden und anderen Geschäftspartnern von diesen 
                  und weiteren daran beteiligten Personen erhalten oder die wir beim Betrieb unserer 
                  Websites, Apps und weiteren Anwendungen von deren Nutzern erheben.
                  {'\n'}
                  {/* Zu den Daten, die wir bearbeiten, gehören insbesondere:  DAFÜR EIN RADIO-BUTTON: JA/NEIN FÜR PERSONENDATEN EINBLENDEN?
                  {'\n'}   */}
                  Soweit dies erlaubt ist, entnehmen wir auch aus öffentlich zugänglichen Quellen 
                  (z.B. Betreibungsregister, Grundbücher, Handelsregister, Presse, Internet) 
                  gewisse Daten oder erhalten solche von Behörden und sonstigen Dritten. 
                  Nebst den Daten von Ihnen, die Sie uns direkt geben, umfassen die Kategorien von 
                  Personendaten, die wir von Dritten über Sie erhalten, insbesondere Angaben aus öffentlichen 
                  Registern, Angaben, die wir im Zusammenhang mit behördlichen und gerichtlichen Verfahren 
                  erfahren, Angaben im Zusammenhang mit ihren beruflichen Funktionen und Aktivitäten 
                  (damit wir z.B. mit Ihrer Hilfe Geschäfte mit Ihrem Arbeitgeber abschliessen und abwickeln 
                  können), Angaben über Sie in Korrespondenz und Besprechungen mit Dritten, Bonitätsauskünfte 
                  (soweit wir mit Ihnen persönlich Geschäfte abwickeln), Angaben über Sie, die uns Personen aus 
                  Ihrem Umfeld (Familie, Berater, Rechtsvertreter, etc.) geben, damit wir Verträge mit Ihnen oder 
                  unter Einbezug von Ihnen abschliessen oder abwickeln können (z.B. Referenzen, Ihre Adresse 
                  für Lieferungen, Vollmachten, Angaben zur Einhaltung gesetzlicher Vorgaben wie etwa 
                  der Geldwäschereibekämpfung und Exportrestriktionen, Angaben von Banken, 
                  Versicherungen, Vertriebs- und anderen Vertragspartnern von uns zur Inanspruchnahme 
                  oder Erbringung von Leistungen durch Sie (z.B. erfolgte Zahlungen, erfolgte Käufe)), 
                  Angaben aus Medien und Internet zu Ihrer Person (soweit dies im konkreten Fall angezeigt 
                  ist, z.B. im Rahmen einer Bewerbung, Presseschau, Marketing/Verkauf, etc.), Ihre Adressen 
                  und ggf. Interessen und weitere soziodemographische Daten (für Marketing), Daten im 
                  Zusammenhang mit der Benutzung der Website (z.B. IP-Adresse, MAC-Adresse des Smartphones 
                  oder Computers, Angaben zu Ihrem Gerät und Einstellungen, Cookies, Datum und Zeit des 
                  Besuchs, abgerufene Seiten und Inhalte,benutzte Funktionen, verweisende Website, Standortangaben).
                  </Text>
                </View>


          {/* Zweck */}
              <View style={pdfStyles.sectionTitle}>
                <Text>Zweck der Datenbearbeitungen</Text>
              </View>
                <View>
                  <Text style={pdfStyles.sectionText}>
                  Wir verwenden die von uns erhobenen Personendaten in erster Linie, um unsere 
                  Verträge mit unseren Kunden und Geschäftspartnern abzuschliessen und abzuwickeln, 
                  so insbesondere im Rahmen der Gastro Services und den Einkauf von Produkten und 
                  Dienstleistungen von unseren Lieferanten und Subunternehmern, sowie um unseren 
                  gesetzlichen Pflichten im In- und Ausland nachzukommen. Wenn Sie für einen solchen 
                  Kunden oder Geschäftspartner tätig sind, können Sie in dieser Funktion mit Ihren 
                  Personendaten natürlich ebenfalls davon betroffen sein.
                  {'\n'}
                  Darüber hinaus bearbeiten wir Personendaten von Ihnen und weiteren Personen, soweit 
                  erlaubt und es uns als angezeigt erscheint, auch für folgende Zwecke, 
                  an denen wir (und zuweilen auch Dritte) ein dem Zweck entsprechendes 
                  berechtigtes Interesse haben:
                  {'\n'}
                  </Text>
                  {data.step3.purpose?.preset?.map((point, i) => (
                    <View style={{flexDirection: 'row',marginBottom: 1, fontSize: 11 }}
                      key={i}
                    >
                      <Text style={{ fontSize: 11, marginLeft: 5, marginRight: 8 }}>•</Text>
                      <Text>{PURPOSES[point.value - 1]} </Text>
                    </View>
                  ))}
                </View>

              {/* Ausland    KEVIN DIE FRAGEN ZU AUSLAND UND BEKANNTGABE AN DRITTE WURDEN VERMISCHT, ICH HABE ES JETZT UMGESCHRIEBEN ZU DATENBEKANNTGABE, AUSLAND MÜSSEN WIR NOCH MACHEN*/}
              <View style={pdfStyles.sectionTitle}>
                <Text>Datenbekanntgabe</Text> 
              </View>
              <View style={pdfStyles.sectionText}>
                <Text>
                  {data.step4a.abroadRadio === 'no'
                    ? 'Wir übermitteln keine Daten an Dritte. Sobald dies der Fall werden sollte, werden wir an dieser Stelle darüber informieren.'
                    : 'Wir geben im Rahmen unserer geschäftlichen Aktivitäten und der hier erwähnten Zwecke der Bearbeitung, soweit erlaubt und es uns als angezeigt erscheint, auch Dritten bekannt. Sei es, weil sie diese für uns bearbeiten oder für ihre eigenen Zwecke verwenden. Dabei handelt es sich insbesondere um folgende Stellen:'}
                </Text>
                {data.step4a.abroadRadio === 'yes' && data.step4a.abroadDropdown?.preset?.map((point, i) => (
                  <View key={i} style={{flexDirection: 'row', marginBottom: 1}}>
                    <Text style={{ marginLeft: 5, marginRight: 8 }}>•</Text>
                    <Text>{DATA_ABROAD[point.value - 1]}</Text>
                  </View>
                ))}
              </View>
              
          {/* Dauer */}
              <View style={pdfStyles.sectionTitle}>
                    <Text>Dauer der Aufbewahrung</Text>
              </View>
                <View>
                    <Text style={pdfStyles.sectionText}>
                    Wir verarbeiten und speichern Ihre Personendaten, solange es für die 
                    Erfüllung unserer vertraglichen und gesetzlichen Pflichten oder sonst 
                    die mit der Bearbeitung verfolgten Zwecke erforderlich ist, d.h. also 
                    zum Beispiel für die Dauer der gesamten Geschäftsbeziehung (von der Anbahnung, 
                    Abwicklung bis zur Beendigung eines Vertrags) sowie darüber hinaus gemäss den 
                    gesetzlichen Aufbewahrungs- und Dokumentationspflichten. Dabei ist es möglich, 
                    dass Personendaten für die Zeit aufbewahrt werden, in der Ansprüche gegen unser 
                    Unternehmen geltend gemacht werden können und soweit wir anderweitig gesetzlich 
                    dazu verpflichtet sind oder berechtigte Geschäftsinteressen dies erfordern (z.B. 
                    für Beweis- und Dokumentationszwecke). Sobald Ihre Personendaten für die oben 
                    genannten Zwecke nicht mehr erforderlich sind, werden sie grundsätzlich und 
                    soweit möglich gelöscht oder anonymisiert.
                    </Text>
                  </View>  

          {/* Datensicherheit */}
                <View style={pdfStyles.sectionTitle}>
                    <Text>Datensicherheit</Text>
                </View>
                <View>
                  <Text style={pdfStyles.sectionText}>
                    Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr 
                    ernst und haben technische und organisatorische Massnahmen 
                    getroffen, um die Sicherheit Ihrer Daten zu gewährleisten. 
                    Insbesondere speichern und verarbeiten wir unsere Daten 
                    unter hohen Sicherheitsstandards.{'\n\n'}      
                  </Text>
                  {/* KEVIN: Diesen Teil nur anzeigen, wenn step6.showToms === toms BITTE DEN NEU ERSTELLTEN RADIO BUTTON VERWENDEN  */}
                  <Text style={pdfStyles.sectionText}>Unter anderem haben wir folgende Massnahmen umgesetzt:{'\n\n'}</Text>  
                  {selectedTomsData && (
                    <>
                      {Object.entries(selectedTomsData)
                        .filter(([key]) => key.includes('step'))
                        .map(([step, stepData], idx) => (
                          <View style={pdfStyles.section} key={step}>
                            <Text style={pdfStyles.sectionText}>{TITLES[idx]}</Text>
                            {Object.values(stepData).flatMap((points) =>
                              Array.isArray(points)
                                ? points.map((obj, i) => (
                                    <View style={{ marginBottom: 2 }} key={i}>
                                      <Text style={{fontSize: 11, marginLeft: 20, marginBottom: 1 }}>• {obj.label}</Text>
                                    </View>
                                  ))
                                : null
                            )}
                          </View>
                        ))}
                    </>
                  )}
                </View>

                  {/* Betroffenenrechte */}
                  <View style={pdfStyles.sectionTitle}>
                  <Text>Ihre Rechte als betroffene Person</Text>
                    </View>
                    <View>
                        <Text style={pdfStyles.sectionText}>
                            {data.step1.law.value === 1    
                            ? `Sie haben das Recht:{'\n'}
                                - auf Auskunft (Art. 25 DSG),{'\n'}
                                - auf Datenherausgabe und -übertragung (Art. 28 DSG),{'\n'}
                                - auf Berichtigung (Art. 32 DSG),{'\n'}
                                - und auf Klagen zum Schutz Ihrer Persönlichkeit (Art. 32 DSG).{'\n\n'}
                                Die Ausübung solcher Rechte setzt in der Regel voraus, dass Sie Ihre Identität eindeutig nachweisen (z.B. durch eine Ausweiskopie, wo Ihre Identität sonst nicht klar ist bzw. verifiziert werden kann). Zur Geltendmachung Ihrer Rechte können Sie uns unter unserer angegebenen Adresse kontaktieren.`
                            : data.step1.law.value === 2
                            ? `Sie haben im Rahmen der DSGVO das Recht auf Auskunft, Berichtigung, Löschung, das Recht auf Einschränkung der Datenbearbeitung und sonst dem Widerspruch gegen unsere Datenbearbeitungen, insbesondere jener für Zwecke des Direktmarketings und weiterer berechtigter Interessen an der Bearbeitung sowie auf Herausgabe gewisser Personendaten zwecks Übertragung an eine andere Stelle (sog. Datenportabilität).{'\n\n'}
                              Bitte beachten Sie aber, dass wir uns vorbehalten, unsererseits die gesetzlich vorgesehenen Einschränkungen geltend zu machen, etwa wenn wir zur Aufbewahrung oder Bearbeitung gewisser Daten verpflichtet sind, daran ein überwiegendes Interesse haben (soweit wir uns darauf berufen dürfen) oder sie für die Geltendmachung von Ansprüchen benötigen. Falls für Sie Kosten anfallen, werden wir Sie vorab informieren.{'\n\n'}
                              Beachten Sie, dass die Ausübung dieser Rechte im Konflikt zu vertraglichen Abmachungen stehen kann und dies Folgen wie z.B. die vorzeitige Vertragsauflösung oder Kostenfolgen haben kann. Wir werden Sie diesfalls vorgängig informieren, wo dies nicht bereits vertraglich geregelt ist.{'\n\n'}
                              Die Ausübung solcher Rechte setzt in der Regel voraus, dass Sie Ihre Identität eindeutig nachweisen (z.B. durch eine Ausweiskopie, wo Ihre Identität sonst nicht klar ist bzw. verifiziert werden kann). Zur Geltendmachung Ihrer Rechte können Sie uns unter der angegebenen Adresse kontaktieren. Jede betroffene Person hat überdies das Recht, ihre Ansprüche gerichtlich durchzusetzen oder bei der zuständigen Datenschutzbehörde eine Beschwerde einzureichen.`
                            : `Sie haben im Rahmen des auf Sie anwendbaren Datenschutzrechts und soweit darin vorgesehen das Recht auf Auskunft, Berichtigung, Löschung, das Recht auf Einschränkung der Datenbearbeitung und sonst dem Widerspruch gegen unsere Datenbearbeitungen, insbesondere jener für Zwecke des Direktmarketings und weiterer berechtigter Interessen an der Bearbeitung sowie auf Herausgabe gewisser Personendaten zwecks Übertragung an eine andere Stelle (sog. Datenportabilität).{'\n\n'}
                              Bitte beachten Sie aber, dass wir uns vorbehalten, unsererseits die gesetzlich vorgesehenen Einschränkungen geltend zu machen, etwa wenn wir zur Aufbewahrung oder Bearbeitung gewisser Daten verpflichtet sind, daran ein überwiegendes Interesse haben (soweit wir uns darauf berufen dürfen) oder sie für die Geltendmachung von Ansprüchen benötigen. Falls für Sie Kosten anfallen, werden wir Sie vorab informieren.{'\n\n'}
                              Beachten Sie, dass die Ausübung dieser Rechte im Konflikt zu vertraglichen Abmachungen stehen kann und dies Folgen wie z.B. die vorzeitige Vertragsauflösung oder Kostenfolgen haben kann. Wir werden Sie diesfalls vorgängig informieren, wo dies nicht bereits vertraglich geregelt ist.{'\n\n'}
                              Die Ausübung solcher Rechte setzt in der Regel voraus, dass Sie Ihre Identität eindeutig nachweisen (z.B. durch eine Ausweiskopie, wo Ihre Identität sonst nicht klar ist bzw. verifiziert werden kann). Zur Geltendmachung Ihrer Rechte können Sie uns unter der angegebenen Adresse kontaktieren. Jede betroffene Person hat überdies das Recht, ihre Ansprüche gerichtlich durchzusetzen oder bei der zuständigen Datenschutzbehörde eine Beschwerde einzureichen. Die zuständige Datenschutzbehörde der Schweiz ist der Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte (http://www.edoeb.admin.ch).`
                            }
                        </Text>
                    </View>




          {/* Änderungen */}
            <View style={pdfStyles.sectionTitle}>
                <Text>Änderungen</Text>
            </View>
                <View>
                    <Text style={pdfStyles.sectionText}>
                    Wir können diese Datenschutzerklärung jederzeit ohne Vorankündigung anpassen. 
                    Es gilt die jeweils aktuelle, auf unserer Website publizierte Fassung. 
                    Soweit die Datenschutzerklärung Teil einer Vereinbarung mit Ihnen ist, 
                    werden wir Sie im Falle einer Aktualisierung über die Änderung per E-Mail 
                    oder auf andere geeignete Weise informieren.
                    </Text>
                </View>  
                
                <View
                  style={{
                    // borderTopColor: 'grey',
                    // borderTopWidth: '1px',
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                />
          {/* Letze Bearbeitung */}
              <View style={pdfStyles.sectionText}>
                <Text>
                  Stand: {data.lastEdited.replace(/-/g, '.')}
                </Text>
              </View>
          </View>
        )}
      </Page>
    </Document>
  );
};

export { DetailedViewPdf };
