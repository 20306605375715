import React from 'react';
import {
  DeleteModal,
  Loader,
  MobileNavbar,
  Navbar,
  UploadsTable,
} from 'components';
import { useApi } from 'services/axios';
import { azureStorageConfig } from 'msalConfig';
import { BlobServiceClient } from '@azure/storage-blob';
import { useTranslation } from 'react-i18next';
import { getStorageEnv } from 'services/getStorageEnv';
import { useNavigate } from 'react-router-dom';
import { CyberverRequest } from './cyberverRequest';

import styles from './styles.module.css';

const Cyberver = () => {
  const { api, isApiReady } = useApi();
  const { t } = useTranslation('common');
  const [uploadsData, setUploadsData] = React.useState(null);
  const [meData, setMeData] = React.useState(null);
  const [showUploadsModal, setShowUploadsModal] = React.useState(false);
  const navigate = useNavigate();

  const blobClient = new BlobServiceClient(
    `https://${azureStorageConfig.ACCOUNT}.blob.core.windows.net/?${azureStorageConfig.SAS_TOKEN}`
  );
  const containerClient = blobClient.getContainerClient(
    `user${meData?.id}-${getStorageEnv()}`
  );

  const fetchData = async () => {
    try {
      const uploadsResponse = await api.get('/cyberver-uploads');
      const meResponse = await api.get('/me');

      setUploadsData(uploadsResponse.data);
      setMeData(meResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleDeleteUploads = async () => {
    if (!showUploadsModal.name) return;

    const options = {
      deleteSnapshots: 'include',
    };

    const blockBlobClient = containerClient.getBlockBlobClient(
      `CYBERVER/${showUploadsModal.name}`
    );
    await blockBlobClient.deleteIfExists(options);
    await api.delete(`/cyberver-uploads/${showUploadsModal.id}`);

    fetchData();
    setShowUploadsModal(false);
  };

  const handleDownload = async (filename) => {
    const blockBlobClient = containerClient.getBlockBlobClient(
      `CYBERVER/${filename}`
    );
    try {
      const blobUrl = blockBlobClient.url;

      const anchor = document.createElement('a');
      anchor.style.display = 'none';
      document.body.appendChild(anchor);

      anchor.href = blobUrl;
      anchor.download = filename;

      anchor.click();
      document.body.removeChild(anchor);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const renderUploadsTable = () => {
    if (uploadsData?.length === 0) {
      return <span>{t('noDataFound')}</span>;
    }

    if (!uploadsData) {
      return <Loader />;
    }

    return (
      <UploadsTable
        data={uploadsData}
        setShowModal={setShowUploadsModal}
        handleDownload={handleDownload}
      />
    );
  };

  React.useEffect(() => {
    if (isApiReady && !meData && !uploadsData) {
      fetchData();
    }
  }, [isApiReady]);

  return (
    <div className="wrapper">
      <Navbar />
      <MobileNavbar />
      <div id="content" className={styles.content}>
        <div
          className="container"
          style={{
            width: '100%',
            padding: '0',
          }}
        >
          <div
            className="row"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div className={`card  ${styles.overviewCard}`}>
              <CyberverRequest />
            </div>

            <div
              className={`card morph_variation1 padder ${styles.overviewCard}`}
              style={{ gap: '25px' }}
            >
              <div className={styles.overviewHeadingWrapper}>
                <h4 className={styles.overviewHeading}>
                  Cyberversicherung - Dateien
                </h4>

                <div className={styles.headingBtnWrapper}>
                  <button
                    className="btn btn-primary"
                    onClick={() => navigate('/CyberverUpload')}
                  >
                    {t('overview.createBtn')}
                  </button>
                </div>
              </div>
              <div style={{ overflowX: 'auto' }}>{renderUploadsTable()}</div>
              {showUploadsModal && (
                <DeleteModal
                  onCancel={() => setShowUploadsModal(false)}
                  onConfirm={() => handleDeleteUploads()}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Cyberver };
